import { useContext, useEffect } from "react";
import OLVectorLayer from "ol/layer/Vector";
import MapContext from "../FHMapContext";
import { vector } from "../Source";

const VectorLayer = ({ source, style, zIndex = 0, features }: any) => {
    const { map }: any = useContext(MapContext);
    useEffect(() => {

        if (!map) return;

        // eslint-disable-next-line prefer-const
        let vectorLayer = source ? new OLVectorLayer({
            source,
            style
        }) : new OLVectorLayer({
            source: vector({ features }),
            style
        })
        map.addLayer(vectorLayer);

        vectorLayer.setZIndex(zIndex);
        return () => {
            if (map) {
                map.removeLayer(vectorLayer);
            }
        };
    }, [map, source, features]);
    return null;
};
export default VectorLayer;