import { School } from "../../model/School";

const school = (state = null as any, action: { type: any; school: School | undefined }) => {

    if (action.type === 'SET_SCHOOL') {
        state = action.school;
    }

    return state
}

export default school;