
import React, { useContext, useEffect } from "react";
import MapContext from "../FHMapContext";
import { Overlay } from "ol";
import { Typography, Space, Row, Col } from 'antd';
import { renderToStaticMarkup } from "react-dom/server"

const { Text, Link } = Typography;

interface FHOverlaysProps {
    enable: boolean;
}

const FHOverlays = (props: FHOverlaysProps) => {
    const { map }: any = useContext(MapContext);
    useEffect(() => {

        if (!map) return;

        const element: any = document.getElementById('popup');
        const content = document.getElementById('popup-content');
        const closer = document.getElementById('popup-closer');

        const popup = new Overlay({
            element: element!,
            positioning: 'bottom-center',
            stopEvent: false,
        });

        closer!.onclick = function () {
            popup.setPosition(undefined);
            closer!.blur();
            return false;
        };

        map.addOverlay(popup);

        props.enable && map.on('singleclick', function (evt: { pixel: any; coordinate: any; }) {
            const features = map.getFeaturesAtPixel(evt.pixel);

            if (features) {
                popup.setPosition(evt.coordinate);
                const innerContent = features.map((feature: any) => {
                    
                    return <Row key={"acbde"}> <Col  style={{ maxHeight: "20px !important" }}> <Text style={{ maxHeight: "20px" }}>{feature.get('Info')}</Text></Col></Row>;
                })
                const reactElement = <Row>

                    {innerContent}
                </Row>

                content!.innerHTML = `<div>${renderToStaticMarkup(innerContent)}</div>`;
            } else {
                popup.setPosition(undefined);
                closer!.blur();
            }
        });

    }, [map]);
    return null;
};
export default FHOverlays;