
import React, { Component, useContext } from "react";
import * as XLSX from "xlsx";
import { Button, message } from "antd";
// import "./styles.css";
import Icon from "@ant-design/icons";
import { updateItemOnDb } from "../dto/ServerHelper";
import UserContext from "../Context/User";

let ORGANISATION = "1";
let excelData: any;
// eslint-disable-next-line prefer-const
let index: any[] = [];
let CLASS_NAME = index[0];
let PASSENGER_NAME_SURNAME = index[1];
let GUARDIAN_NAME = index[2];
let GUARDIAN_FIRST_PHONE_NUMBER = index[3];
let GUARDIAN_SECOND_PHONE_NUMBER = index[5];
let ADDRESS = index[6];
let ROUTE = index[7];
let DRIVER_NAME = index[8];
let CAR_NUMBER_PLATE = index[9];
let DIRVER_PHONE_NUMBER = index[10];
let SHUTTLE_NAME = index[11];
let SHUTTLE_FIRST_TIME = index[12];
let SHUTTLE_SECOND_TIME = index[13];

const turkishtoEnglish = (text: string) => {
    // eslint-disable-next-line prefer-const
    let Maps: any = {
        "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
        "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
    };
    Object.keys(Maps).forEach(function (Old) {
        text = text.replace(Old, Maps[Old]);
    });
    return text;

};

function getDriverId(driverData: any, carNumberPlate: any) {
    for (const key in driverData) {
        const element = driverData[key];
        if (element.CarId.toString() === carNumberPlate.toString()) {
            return key;
        }
    }
    return "";
}

function getPassengers(passengerData: any, shuttleId: any) {

    // eslint-disable-next-line prefer-const
    let passengers = [];
    for (const key in passengerData) {

        const element = passengerData[key];
        if (element.ShuttleId.split(",").indexOf(shuttleId) !== -1) {
            passengers.push(element.UserName);
        }

    }
    return passengers.join(",");

}

function getShuttleId(data: any) {
    return `${turkishtoEnglish(String(data[ROUTE]).replace(/\s/g, '').toUpperCase())}-${data[CAR_NUMBER_PLATE].replace(/\s/g, '')}`;
}

function createCarTable() {

    // eslint-disable-next-line prefer-const
    let JSON_Obj: any = {};

    excelData.forEach((data: any) => {

        // eslint-disable-next-line prefer-const
        let carData: any = {};

        // eslint-disable-next-line prefer-const
        let carNumberPlate = data[CAR_NUMBER_PLATE].replace(/\s/g, '').toUpperCase();

        carData["CarNumberPlate"] = carNumberPlate;
        carData["InsuranceDate"] = "30102022";
        carData["KM"] = "10000";
        carData["PassengerCount"] = "20";
        carData["Status"] = "N";
        carData["VehicleInspectionDate"] = "30102022";
        carData["Organisation"] = ORGANISATION;

        JSON_Obj[carNumberPlate] = carData;

    });


    for (const key in JSON_Obj) {
        updateItemOnDb("Car", JSON_Obj[key], key)
    }

}

function createDriverTable() {

    // eslint-disable-next-line prefer-const
    let JSON_Obj: any = {};

    excelData.forEach((data: any) => {

        // eslint-disable-next-line prefer-const
        let dirverData: any = {};

        // eslint-disable-next-line prefer-const
        let key = String(data[DIRVER_PHONE_NUMBER]).toString().replace(/\s/g, '');
        key = key.substring(key.length - 10);
        // eslint-disable-next-line prefer-const
        let shuttleId = getShuttleId(data);

        // eslint-disable-next-line prefer-const
        let carNumberPlate = data[CAR_NUMBER_PLATE].replace(/\s/g, '');

        dirverData["CarId"] = carNumberPlate;
        dirverData["Name"] = data[DRIVER_NAME];
        dirverData["Password"] = "1111";
        dirverData["PhoneNumber"] = key;
        dirverData["Status"] = "N";
        dirverData["Surname"] = "-";
        dirverData["UserName"] = key;
        dirverData["Organisation"] = ORGANISATION;
        dirverData["ShuttleId"] = `${shuttleId},R-${shuttleId}`;

        JSON_Obj[key] = dirverData;

    });

    for (const key in JSON_Obj) {
        updateItemOnDb("Driver", JSON_Obj[key], key)
    }

    return JSON_Obj;

}

function createShuttleTable(passengerData: any, driverData: any) {

    // eslint-disable-next-line prefer-const
    let parsedData = [];

    // eslint-disable-next-line prefer-const
    let JSON_Obj: any = {};
    excelData.forEach((data: any) => {

        // eslint-disable-next-line prefer-const
        let shuttleData: any = {};

        // eslint-disable-next-line prefer-const
        let shuttleId = getShuttleId(data);
        shuttleData["AvaregeTime"] = "00:50:00";
        shuttleData["CarNumberPlate"] = data[CAR_NUMBER_PLATE].replace(/\s/g, '');
        shuttleData["Direction"] = "Gidiş";
        shuttleData["DriverId"] = getDriverId(driverData, data[CAR_NUMBER_PLATE].toString().replace(/\s/g, ''));
        shuttleData["Organisation"] = ORGANISATION;
        shuttleData["OrganisationName"] = "Ted Hatay Koleji";
        shuttleData["Remember"] = "06:30:00";
        shuttleData["Route"] = data[ROUTE];
        shuttleData["ShuttleName"] = data[SHUTTLE_NAME];
        shuttleData["ShuttleTime"] = data[SHUTTLE_FIRST_TIME];
        shuttleData["Status"] = "N";
        shuttleData["Passengers"] = getPassengers(passengerData, shuttleId);

        JSON_Obj[shuttleId] = shuttleData;

        // eslint-disable-next-line prefer-const
        let returnShuttleDate = Object.assign({}, shuttleData);
        returnShuttleDate["Direction"] = "Dönüş";
        returnShuttleDate["ShuttleTime"] = data[SHUTTLE_SECOND_TIME];
        shuttleData["Passengers"] = getPassengers(passengerData, `R-${shuttleId}`);
        JSON_Obj[`R-${shuttleId}`] = returnShuttleDate;

    });

    for (const key in JSON_Obj) {
        updateItemOnDb("Shuttle", JSON_Obj[key], key)
    }

}


function startToCreation() {

    createCarTable();

    // eslint-disable-next-line prefer-const
    let driverTable = createDriverTable();

    // eslint-disable-next-line prefer-const
    let JSON_Obj: any = {};

    excelData.forEach((data: any) => {

        // eslint-disable-next-line prefer-const
        let nameSurname = data[PASSENGER_NAME_SURNAME];

        // eslint-disable-next-line prefer-const
        let splitName = turkishtoEnglish(nameSurname.toUpperCase()).split(" ");
        // eslint-disable-next-line prefer-const
        let lastChar = splitName[1] ? splitName[1].substr(0, 1) : '';
        // eslint-disable-next-line prefer-const
        let userName = splitName[0] + lastChar + Math.floor(Math.random() * 9000000 + 10).toString();

        if (JSON_Obj[userName]) {
            JSON_Obj[userName]["ShuttleId"] = `${JSON_Obj[userName]["ShuttleId"]},${turkishtoEnglish(String(data[ROUTE]).replace(/\s/g, '').toUpperCase())}`
        } else {

            // eslint-disable-next-line prefer-const
            let passengerData: any = {};

            const passengerShuttleId = getShuttleId(data);

            let phoneNumber1 = "-";
            console.log("-----------")
            if (data[GUARDIAN_FIRST_PHONE_NUMBER] && data[GUARDIAN_FIRST_PHONE_NUMBER].toString().length > 9) {
                phoneNumber1 = String(data[GUARDIAN_FIRST_PHONE_NUMBER]).toString().replace(/\s/g, '');
                phoneNumber1 = phoneNumber1.substring(phoneNumber1.length - 10);
            }

            let phoneNumber2 = "-";
            if (data[GUARDIAN_SECOND_PHONE_NUMBER] && data[GUARDIAN_SECOND_PHONE_NUMBER].toString().length > 9) {
                phoneNumber2 = String(data[GUARDIAN_SECOND_PHONE_NUMBER]).toString().replace(/\s/g, '');
                phoneNumber2 = phoneNumber2.substring(phoneNumber2.length - 10);
            }

            passengerData["ShuttleId"] = `${passengerShuttleId},R-${passengerShuttleId}`
            passengerData["UserName"] = userName;
            passengerData["Password"] = Math.floor(Math.random() * 900000 + 10).toString();
            passengerData["PassengerName"] = nameSurname;
            passengerData["Status"] = "N";
            passengerData["Address"] = data[ADDRESS];
            passengerData["Guardian"] = data[GUARDIAN_NAME];
            passengerData["GuardianNumber"] = phoneNumber1;
            passengerData["GuardianNumber2"] = phoneNumber2;
            passengerData["Organisation"] = ORGANISATION;
            passengerData["Latitude"] = 36.2025362;
            passengerData["Longitude"] = 36.1601354;
            passengerData["IsLogedin"] = "0";
            passengerData["ClassName"] = data[CLASS_NAME];
            passengerData["Comment"] = '-';
            passengerData["IsNotificationsEnabled"] = false;
            passengerData["IsNotificationsEnabled2"] = false;

            JSON_Obj[userName] = passengerData;
        }
    });

    for (const key in JSON_Obj) {
        updateItemOnDb("Passenger", JSON_Obj[key], key)
    }

    createShuttleTable(JSON_Obj, driverTable);
}

const importExcel = (file: any, addItem: any) => {
    const fileReader = new FileReader();
    fileReader.onload = event => {
        try {
            const { result }: any = event.target;
            const workbook = XLSX.read(result, { type: "binary" });
            for (const Sheet in workbook.Sheets) {
                if (Object.prototype.hasOwnProperty.call(workbook.Sheets, Sheet)) {
                    excelData = XLSX.utils.sheet_to_json(workbook.Sheets[Sheet]);
                }

                for (const x in excelData[0]) {
                    index.push(x);
                }

                CLASS_NAME = index[0];
                PASSENGER_NAME_SURNAME = index[1];
                GUARDIAN_NAME = index[2];
                GUARDIAN_FIRST_PHONE_NUMBER = index[3];
                GUARDIAN_SECOND_PHONE_NUMBER = index[5];
                ADDRESS = index[6];
                ROUTE = index[7];
                DRIVER_NAME = index[8];
                CAR_NUMBER_PLATE = index[9];
                DIRVER_PHONE_NUMBER = index[10];
                SHUTTLE_NAME = index[11];
                SHUTTLE_FIRST_TIME = index[12];
                SHUTTLE_SECOND_TIME = index[13];

                startToCreation();
            }

            message.success("Yükleme başarılı!");
        } catch (e) {
            console.log(e)
            message.error("Yükleme başarısız. Sistem yöneticiniz ile iletişime geçiniz!");
        }
    };
    fileReader.readAsBinaryString(file);
};


const UploadData = (props: any) => {

    const [user, setUser] = useContext(UserContext);
    ORGANISATION = user.Organization.toString();


    const onImportExcel = (event: { target: { files: any; }; }) => {
        const { files } = event.target;
        if (files.length === 1) {
            importExcel(
                files[0],
                (sku: any, description: any, quantity: any, cost: any) => console.log(sku, description, quantity, cost),
            );
        }
    };

    return (
        <div style={{ marginTop: 100 }}>
            <p>Verileri yüklerken dikkat edilecekler:</p>
            <ol>
                <li><p><a href="https://docs.google.com/spreadsheets/d/1S7cpbrlSTWZEXEMQBLuKfZa04ZNHFYC9/edit?usp=sharing&ouid=115754342848696954657&rtpof=true&sd=true">Örnek format</a></p></li>
                <li><p>Boş alan olmamalı, bilgi olmayan alanlara &quot;-&quot; işareti eklenmelidir.</p></li>
                <li><p>Sadece yeni veriler eklenmelidir. Güncellemeler <a href="https://basbus.site/">Websitesi</a> ile yapılmalıdır.</p>
                </li>
            </ol>

            <br></br>

            <Button className="upload-wrap">
                <Icon type="upload" />
                <input className="file-uploader" type="file" accept=".xlsx, .xls" onChange={onImportExcel} />
                <span className="upload-text">Dosya Yükle</span>
            </Button>
            <p className="upload-tip">Desteklenen dosya formatları: .xlsx, .xls</p>
        </div>
    );
}

export default UploadData;