import { MarkerCoordinate } from "../../model/MarkerCoordinate";

const passengerCoordinate = (state = {} as MarkerCoordinate, action: { type: any; coordinate: MarkerCoordinate }) => {
    switch (action.type) {
        case 'CHANGE_PASSENGER_COORDINATE':
            return action.coordinate;
        default:
            return state
    }
}

export default passengerCoordinate;