import React, { useEffect, useState } from "react";
import FHMap from "./FHMap";
import { Style, Icon, Stroke, Text, Fill } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { osm, vector } from "./Source";
import { fromLonLat, get } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import WKT from "ol/format/WKT";
import mapConfig from "./config.json";
import Controls from "./Controls/Controls";
import FullScreenControl from "./Controls/FullScreenControl";
import Layers from "./Layers/Layers";
import TileLayer from "./Layers/TileLayer";
import VectorLayer from "./Layers/VectorLayer";
import FeatureStyles from "./Features/Styles";
import FHGeoLocation from "./FHGeoLocation";
import { MarkerCoordinate } from "../../model/MarkerCoordinate";
import FHMapInteractions from "./Controls/FHMapInteractions";
import utils from "./Utils/utils";
import Polyline from 'ol/format/Polyline';
import { Button, message } from "antd";
import FHOverlays from "./Controls/FHOverlays";
import Geometry from "ol/geom/Geometry";
import moment from "moment";
import { ShuttleBaseTrackRoute } from "../../model/ShuttleBaseTrackRoute";
import { Shuttle } from "../../model/Shuttle";
import { getTableItems, getTableItemWithId } from "../../dto/ServerHelper";
import { XYZ } from "ol/source";
import { Passenger } from "../../model/Passenger";
import RouteDetermination from "../Shuttle/RouteDetermination";
import studentmarker from "../../images/studentMarker.png";
import markerImage64 from "../../images/markerImage64.png";
import { getArea } from 'ol/sphere';

interface FHMapAppProps {
    isGeolocationActive?: boolean;
    isInteractionsOpen?: boolean;
    shuttleRoute?: number[][];
    passengerCoordinates?: MarkerCoordinate[];
    isCalculateRoute?: boolean;
    saveShuttleBaseRoute?: any;
    baseTrackRoute?: ShuttleBaseTrackRoute;
    shuttle?: Shuttle;
    mapCenter?: number[];
    watchShuttleBus?: boolean;
}

const FHMapApp = (props: FHMapAppProps) => {
    const [center, setCenter] = useState(mapConfig.center);
    const [zoom, setZoom] = useState(14);
    const [features, setFeatures] = useState([] as unknown as Feature<Geometry>[]);
    const [routeFeatures, setRouteFeatures] = useState([] as unknown as Feature<Geometry>[]);
    const [allShuttlesPolygonFeature, setAllShuttlesPolygonFeature] = useState(undefined as any);
    const [schoolFeature, setSchoolFeature] = useState(undefined as unknown as Feature<Geometry>);
    const [busFeature, setBusFeature] = useState(undefined as unknown as Feature<Geometry>);
    const [geojsonObject, setGeojsonObject] = useState(undefined as any);
    const [calculatedRoute, setCalculatedRoute] = useState(undefined as unknown as ShuttleBaseTrackRoute);
    const [points, setPoints] = useState([] as number[]);
    const [shuttlRouteByDate, setShuttlRouteByDate] = useState([] as number[]);
    const [shuttlRouteDate, setShuttlRouteDate] = useState(undefined);

    useEffect(() => {

        if (props.shuttleRoute && props.shuttleRoute.length > 0) {
            addShuttleRouteToMap(props.shuttleRoute);
        }

        if (props.shuttle) {

            getTableItemWithId<any>("School", props.shuttle.Organisation)
                .then((item: any) => {

                    // eslint-disable-next-line prefer-const
                    let iconStyle = new Style({
                        image: new Icon({
                            anchorXUnits: "fraction",
                            anchorYUnits: "pixels",
                            src: require(`../../images/school.png`),
                        }),
                    });

                    // eslint-disable-next-line prefer-const
                    let feature = new Feature({
                        geometry: new Point(fromLonLat([item.Longitude, item.Latitude])),
                    });
                    feature.setId(item.Id)
                    feature.set('Info', item.Name)
                    feature.set('Longitude', item.Longitude)
                    feature.set('Latitude', item.Latitude)
                    feature.setStyle(iconStyle);
                    feature.set("type", 'place');

                    setSchoolFeature(feature);
                });
        }

    }, [props.shuttleRoute])

    useEffect(() => {
        if (shuttlRouteByDate && shuttlRouteByDate.length > 0) {
            addShuttleRouteToMap(shuttlRouteByDate);
        }
    }, [shuttlRouteByDate])

    useEffect(() => {
        if (props.passengerCoordinates && props.passengerCoordinates.length > 0) {

            // eslint-disable-next-line prefer-const
            let featuresPassenger: any = addMarkers(props.passengerCoordinates, studentmarker);

            setFeatures(oldArray => [...featuresPassenger, ...oldArray]);

            if (props.passengerCoordinates && props.passengerCoordinates.length > 0) {
                // eslint-disable-next-line prefer-const
                let nearestPoints: number[] = [];
                const element = props.passengerCoordinates[0];
                // eslint-disable-next-line prefer-const
                let coordinate = [element.Longitude, element.Latitude];
                getNearestNext(coordinate, 0, nearestPoints);
            }

            setCenter([props.passengerCoordinates[0].Longitude, props.passengerCoordinates[0].Latitude]);
        }
    }, [props.passengerCoordinates])

    useEffect(() => {
        if (props.baseTrackRoute) {
            const feature = ConvertRouteGeometryToFeature(props.baseTrackRoute.Geometry);
            setRouteFeatures(oldArray => [feature, ...oldArray]);
        }
    }, [props.baseTrackRoute])

    useEffect(() => {
        if (props.mapCenter)
            setCenter(props.mapCenter);
    }, [props.mapCenter])

    useEffect(() => {
        if (props.watchShuttleBus) {

            getTableItems("Passenger").then((result: Passenger[]) => {

                const allPassengers = result.map((item) => {
                    return { Id: item.Id, Info: item.PassengerName, Latitude: item.Latitude, Longitude: item.Longitude } as MarkerCoordinate
                })

                // eslint-disable-next-line prefer-const
                let featuresPassenger: any = addMarkers(allPassengers, studentmarker);

                setFeatures(featuresPassenger);

            });

        }
    }, [props.watchShuttleBus])


    const addShuttleRouteToMap = (tempShuttleRoute: any) => {

        // eslint-disable-next-line prefer-const
        let temp = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {
                    },
                    "geometry": {
                        "type": "LineString",
                        "coordinates": []
                    }
                }
            ]
        };

        tempShuttleRoute.forEach((element: never) => {

            return temp.features[0].geometry.coordinates.push(element);
        });

        setGeojsonObject(temp);

        setCenter(tempShuttleRoute[tempShuttleRoute.length - 1]);
        const startCoordinate = { Id: 1, Latitude: tempShuttleRoute[tempShuttleRoute.length - 1][1], Longitude: tempShuttleRoute[tempShuttleRoute.length - 1][0] } as unknown as MarkerCoordinate;
        const endCoordinate = { Id: 2, Latitude: tempShuttleRoute[0][1], Longitude: tempShuttleRoute[0][0] } as unknown as MarkerCoordinate;

        setFeatures(oldArray => [...addMarkers([startCoordinate, endCoordinate], markerImage64) as any, ...oldArray]);

    }

    // eslint-disable-next-line prefer-const
    let styleFunction = (feature: any, markerImage: any) => {
        return [
            new Style({
                image: new Icon({
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    src: markerImage,
                }),
                stroke: new Stroke({
                    color: [0, 0, 0, 1.0],
                    width: 1,
                    lineDash: [1, 5, 3, 5]
                }),
                text: new Text({
                    font: '12px Calibri',
                    text: feature.get('Info') as any,
                    placement: 'line',
                    fill: new Fill({
                        color: '#fff'
                    }),
                    stroke: new Stroke({
                        color: '#000',
                        width: 4
                    })
                }),
            })
        ];
    }

    const addMarkers = (markerCoordinate: MarkerCoordinate[], markerImage: any) => {

        // eslint-disable-next-line prefer-const
        let features = markerCoordinate.map((item: MarkerCoordinate) => {
            // eslint-disable-next-line prefer-const
            let feature = new Feature({
                geometry: new Point(fromLonLat([item.Longitude, item.Latitude])),
            });
            feature.setId(item.Id)
            feature.set('Info', item.Info)
            feature.setStyle(styleFunction(feature, markerImage));
            feature.set("type", 'place');

            return feature;
        });
        return features;
    }

    const ConvertRouteGeometryToFeature = (polyline: any) => {


        // eslint-disable-next-line prefer-const
        let route = new Polyline({
            factor: 1e5
        }).readGeometry(polyline, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });

        // eslint-disable-next-line prefer-const
        let feature = new Feature({
            type: 'route',
            geometry: route
        });

        const styles = [
            // linestring
            new Style({
                stroke: new Stroke({
                    color: 'green',
                    lineDash: [4],
                    width: 4
                }),
            }),
        ];

        // let geom = feature.getGeometry();
        // ///@ts-ignore
        // geom.forEachSegment(function (start: number[], end: number[]) {
        //     const dx = end[0] - start[0];
        //     const dy = end[1] - start[1];
        //     const rotation = Math.atan2(dy, dx);
        //     // arrows
        //     styles.push(
        //         new Style({
        //             geometry: new Point(end),
        //             image: new Icon({
        //                 src: require(`../../images/arrow.png`),
        //                 anchor: [0.75, 0.5],
        //                 rotateWithView: true,
        //                 rotation: -rotation,
        //             }),
        //         })
        //     );
        // });


        feature.setStyle(styles);

        return feature;
    }

    const calculateRoute = (points: any[]) => {
        if (points.length === 0) {
            message.success({
                content: 'Hesaplanacak herhangi bir güzergah bulunamadı.',
                style: {
                    zIndex: "999999999999999",
                    marginTop: '200vh',
                },
            });
            return;
        }
        points.push([schoolFeature.get("Longitude"), schoolFeature.get("Latitude")]);
        const pointsRequest = points.map((item) => { return item.join(); }).join(";");

        fetch('//router.project-osrm.org/route/v1/driving/' + pointsRequest + '?annotations=true').then(function (r) {
            return r.json();
        }).then((json) => {

            if (json.code !== 'Ok') return;
            const polyline = json.routes[0];

            const feature = ConvertRouteGeometryToFeature(polyline.geometry);
            feature.setId(props.shuttle?.Id);

            setRouteFeatures([feature]);

            setCalculatedRoute({
                ShuttleId: props.shuttle?.Id,
                Geometry: polyline.geometry,
                Duration: polyline.duration,
                Distance: polyline.distance,
                Datetime: moment(Date.now()).format('YYYY/MM/DD HH:mm')
            } as ShuttleBaseTrackRoute)
        });
    }

    const getNearestNext = (coordinate: number[], index: number, nearestPoints: number[]) => {
        utils.getNearest(coordinate).then((coord_street: any) => {
            nearestPoints.push(coord_street);
        }).finally(() => {
            if (props.passengerCoordinates && props.passengerCoordinates.length - 1 > index) {
                const element = props.passengerCoordinates[index + 1];
                // eslint-disable-next-line prefer-const
                let coordinate = [element.Longitude, element.Latitude];
                getNearestNext(coordinate, index + 1, nearestPoints);
            } else {
                setPoints(nearestPoints);
            }
        });
    }

    const polygonVectorSource = shuttlRouteByDate && shuttlRouteByDate.length > 0 && geojsonObject && vector({
        features: new GeoJSON().readFeatures(geojsonObject, {
            featureProjection: get("EPSG:3857"),
        }),
    });

    const allShuttlesVectorSource = allShuttlesPolygonFeature && vector({
        features: new GeoJSON().readFeatures(allShuttlesPolygonFeature, {
            featureProjection: get("EPSG:3857"),
        }),
    });

    const vectorSource = vector({ features });

    return (
        <FHMap center={fromLonLat(center)} zoom={zoom}>
            <Layers>
                <TileLayer source={new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
                })} zIndex={0} />

                {/* <TileLayer source={new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
                })} zIndex={0} /> */}

                {props.shuttleRoute && props.shuttleRoute.length > 0 && geojsonObject && (
                    <VectorLayer
                        source={polygonVectorSource}
                        style={FeatureStyles.Polygon}
                    />
                )}

                {shuttlRouteByDate && shuttlRouteByDate.length > 0 && geojsonObject && (
                    <VectorLayer
                        source={polygonVectorSource}
                        style={FeatureStyles.Polygon}
                    />
                )}

                {routeFeatures && <VectorLayer features={routeFeatures} style={FeatureStyles.Polygon} />}
                {allShuttlesVectorSource && <VectorLayer source={allShuttlesVectorSource} style={FeatureStyles.MultiPolygonColorful} />}
                {features && <VectorLayer source={vectorSource} />}
                {schoolFeature && <VectorLayer features={[schoolFeature]} />}
                {busFeature && <VectorLayer features={[busFeature]} />}

            </Layers>
            <Controls>
                {props.isInteractionsOpen && <FHMapInteractions source={vectorSource} />}
                <FullScreenControl />
                {/* {props.isGeolocationActive && <FHGeoLocation />} */}
                <FHOverlays enable={!props.isInteractionsOpen}></FHOverlays>
            </Controls>
            <div id="msg">
                <RouteDetermination isCalculateRoute={props.isCalculateRoute} hidden={props.saveShuttleBaseRoute == undefined} shuttle={props.shuttle} setShuttlRouteByDate={setShuttlRouteByDate} setShuttlRouteDate={setShuttlRouteDate} setAllShuttlesPolygonFeature={setAllShuttlesPolygonFeature}></RouteDetermination>
            </div>

        </FHMap>
    );
};

export default FHMapApp;