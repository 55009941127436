
import React from 'react'

function PrivacyManagment() {

    return (
        <>
            <div className="main-container" style={{ paddingTop: "30px", paddingBottom: "50px", marginRight: "20%", marginLeft: "20%" }}>
                <h2>

                    Basbus Yönetim

                    &gt; Privacy Policy</h2>
                <hr></hr>
                <div id="content">

                    <p> <a href="http://pamasoftware.com/"> Pama Software</a>, Basbus Yönetim uygulamasını ücretsiz bir uygulama olarak geliştirdi. Bu hizmet, <a href="http://pamasoftware.com/"> Pama Software</a> tarafından ücretsiz olarak sağlanmaktadır ve olduğu gibi kullanılması amaçlanmıştır.</p>

                    <p>Bu sayfa, herhangi biri hizmetimizi kullanmaya karar verirse, kişisel bilgilerin toplanması, kullanılması ve ifşa edilmesiyle ilgili politikalarımız hakkında ziyaretçileri bilgilendirmek için kullanılır.</p>
                    <p>Hizmetimizi kullanmayı seçerseniz, bu politikayla ilgili bilgilerin toplanmasını ve kullanılmasını kabul etmiş olursunuz. Topladığımız kişisel bilgiler, hizmeti sağlamak ve geliştirmek için kullanılır. Bilgilerinizi bu Gizlilik Politikasında açıklananlar dışında kimseyle kullanmayacağız veya paylaşmayacağız.</p>
                    <p>Bu Gizlilik Politikasında kullanılan terimler, bu Gizlilik Politikasında aksi belirtilmediği sürece, Basbus Yönetim&apos;den erişilebilen şartlar ve koşullarımızdaki anlamlara sahiptir.</p>
                    <p><strong>Bilgi Toplama ve Kullanım</strong></p>



                    <p>Daha iyi bir deneyim için, Hizmetimizi kullanırken, Anlık Konum dahil ancak bununla sınırlı olmamak üzere, bize kişisel olarak tanımlanabilir bazı bilgileri vermenizi isteyebiliriz. Talep ettiğimiz bilgiler tarafımızca tutulacak ve bu gizlilik politikasında açıklandığı şekilde kullanılacaktır.</p>



                    <p>Uygulama, sizi tanımlamak için kullanılan bilgileri toplayabilen üçüncü taraf hizmetleri kullanır.</p>



                    <p>Uygulama tarafından kullanılan üçüncü taraf hizmet sağlayıcıların gizlilik politikasına bağlantı</p>

                    <p><a href='https://play.google.com/'>Google Play Services</a></p>

                    <p><strong>Çerezler</strong></p>

                    <p>Çerezler, genellikle anonim benzersiz tanımlayıcılar olarak kullanılan az miktarda veri içeren dosyalardır. Bunlar, ziyaret ettiğiniz web sitelerinden tarayıcınıza gönderilir ve cihazınızın dahili hafızasında saklanır.</p>

                    <p>Bu Hizmet, bu &quot;çerezleri&quot; açıkça kullanmaz. Ancak uygulama, bilgi toplamak ve hizmetlerini iyileştirmek için üçüncü taraf kodu ve &quot;çerezler&quot; kullanan kitaplıklar kullanabilir. Bu tanımlama bilgilerini kabul etme veya reddetme seçeneğine sahipsiniz ve cihazınıza bir tanımlama bilgisinin ne zaman gönderildiğini bilirsiniz. Çerezlerimizi reddetmeyi seçerseniz, bu Hizmetin bazı kısımlarını kullanamayabilirsiniz.</p>

                    <p><strong>Servis sağlayıcıları</strong></p>

                    <p>Aşağıdaki nedenlerden dolayı üçüncü taraf şirketleri ve bireyleri istihdam edebiliriz:</p>

                    <p>Hizmetimizi kolaylaştırmak için;</p>

                    <p>Hizmeti bizim adımıza sağlamak için;</p>

                    <p>Hizmetle ilgili hizmetleri gerçekleştirmek için; veya</p>

                    <p>Hizmetimizin nasıl kullanıldığını analiz etmemize yardımcı olmak için.</p>

                    <p>Bu Hizmetin kullanıcılarını, bu üçüncü tarafların Kişisel Bilgilerine erişimi olduğu konusunda bilgilendirmek istiyoruz. Sebebi ise kendilerine verilen görevleri bizim adımıza yerine getirmektir. Ancak, bilgileri başka bir amaçla açıklamamak veya kullanmamakla yükümlüdürler.</p>

                    <p><strong>Güvenlik</strong></p>

                    <p>Bize Kişisel Bilgilerinizi sağlama konusundaki güveninize değer veriyoruz, bu nedenle ticari olarak kabul edilebilir koruma yöntemlerini kullanmaya çalışıyoruz. Ancak internet üzerinden hiçbir iletim yönteminin veya elektronik depolama yönteminin %100 güvenli ve güvenilir olmadığını ve mutlak güvenliğini garanti edemeyiz.</p>

                    <p><strong>Diğer Sitelere Bağlantılar</strong></p>

                    <p>Bu Hizmet, diğer sitelere bağlantılar içermez.</p>

                    <p><strong>Çocukların Gizliliği</strong></p>

                    <p>Bu Hizmetler 13 yaşın altındaki hiç kimseye hitap etmez. 13 yaşın altındaki çocuklardan bilerek kişisel olarak tanımlanabilir bilgiler toplamayız. 13 yaşından küçük bir çocuğun bize kişisel bilgiler verdiğini keşfetmemiz durumunda, bunu derhal sunucularımızdan sileriz. Ebeveyn veya vasi iseniz ve çocuğunuzun bize kişisel bilgiler verdiğini biliyorsanız, gerekli işlemleri yapabilmemiz için lütfen bizimle iletişime geçin.</p>

                    <p><strong>Bu Gizlilik Politikasındaki Değişiklikler</strong></p>

                    <p>Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Bu nedenle, herhangi bir değişiklik için bu sayfayı periyodik olarak gözden geçirmeniz önerilir. Bu sayfada yeni Gizlilik Politikasını yayınlayarak herhangi bir değişiklik hakkında sizi bilgilendireceğiz.</p>

                    <p>Bu politika 2022-08-03 tarihinden itibaren geçerlidir</p>

                    <p><strong>Bize Ulaşın</strong></p>

                    <p>Gizlilik Politikamız ile ilgili herhangi bir sorunuz veya öneriniz varsa, bolatahmett@gmail.com adresinden bizimle iletişime geçmekten çekinmeyin.</p>

                </div>
            </div>

        </>
    );
}

export default PrivacyManagment;
