
import { Button, Form, FormInstance, Input, Popconfirm, Row, Space, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Driver } from '../../model/Driver';
import FHInventory from './FHInventory';
import { Select } from 'antd';
import { Car } from '../../model/Car';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import UserContext from '../../Context/User';

const { Text } = Typography;
const { Option } = Select;

interface FHDriverProps {
    id?: any;
    onClickListItem?: any;
    filter?: any;
}

function FHDriver(props: FHDriverProps) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [carData, setCarData] = useState([] as any[]);
    const [editItem, setEditItem] = useState({} as Driver);
    const [user, setUser] = useContext(UserContext);
    // eslint-disable-next-line prefer-const
    let formRef = React.createRef<FormInstance>();
    const TABLE_NAME = "Driver";

    const searchInput = useRef<any>(null);

    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void, confirm: any, dataIndex: string) => {
        clearFilters();
        handleSearch(confirm)
    };

    const handleSetUserName = (value: string) => {
        if (!data.some((item: any) => item.Id === editItem?.Id)) {
            const key = value.replace(/\s/g, '');
            formRef.current!.setFieldsValue({ UserName: key });
            formRef.current!.setFieldsValue({ Password: Math.floor(Math.random() * 9000 + 10).toString() });
        }
    }

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t(dataIndex)}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filtrele
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Temizle
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        render: (text: any) => text,
    });


    const columns = [
        {
            "title": t("CarId"),
            "dataIndex": "CarId",
            "key": "CarId",
        },
        {
            "title": t("CarNumberPlate"),
            "dataIndex": "CarNumberPlate",
            "key": "CarNumberPlate",
            ...getColumnSearchProps('CarNumberPlate'),
        },
        {
            "title": t("Name"),
            "dataIndex": "Name",
            "key": "Name",
            ...getColumnSearchProps('Name'),
        },
        {
            "title": t("Surname"),
            "dataIndex": "Surname",
            "key": "Surname",
            ...getColumnSearchProps('Surname'),
        },
        {
            "title": t("UserName"),
            "dataIndex": "UserName",
            "key": "UserName"
        },
        {
            "title": t("Password"),
            "dataIndex": "Password",
            "key": "Password"
        },
        {
            "title": t("PhoneNumber"),
            "dataIndex": "PhoneNumber",
            "key": "PhoneNumber"
        },
        {
            "title": t("Id"),
            "dataIndex": "Id",
            "key": "Id"
        },
        {
            title: t('Action'),
            dataIndex: '',
            key: 'x',
            render: (_: any, record: any) =>
                <>
                    <Popconfirm title="Silmek istediğinizden emin misiniz?" onConfirm={() => { record.Status = "D"; saveChanges(record); }}>
                        <a>Sil</a>
                    </Popconfirm>
                    <br></br>
                    {<a onClick={() => setEditItem(record)}>Güncelle</a>}
                </>
            ,
        }
    ] as any;

    useEffect(() => {
        getTableItems(TABLE_NAME).then((result: any[]) => {
            setSpinTip(`Sürücüler verisi yükleniyor!`);
            setData(result.filter((item) => { return item.Status !== "D" && item.Organisation === user.Organization.toString(); }));
            setSpinTip("");
        });

        getTableItems("Car").then((result: any[]) => {
            setCarData(result.filter((item) => { return item.Status !== "D"; }).map((carItem: Car) => {
                return <Option key={carItem.Id} value={carItem.Id}>{carItem.CarNumberPlate}</Option>
            }));
        });

    }, []);

    useEffect(() => {
        form.setFieldsValue(editItem);
    }, [editItem]);

    const onFinish = (values: any) => {
        saveChanges(values);
    }

    const saveChanges = (changedItem: any) => {
        let result = {} as any[];

        changedItem.Organisation = user.Organization.toString();

        if (data.some((item: any) => item.Id === changedItem.Id)) {
            result = data.map(item => (item.Id === changedItem.Id ? changedItem : item));
            setData(result);
        } else {
            changedItem.Id = changedItem.UserName;
            changedItem.Status = "N";
            setData(state => [...state, changedItem]);
        }

        delete changedItem["CarNumberPlate"];
        updateItemOnDb(TABLE_NAME, changedItem, changedItem.Id);
        setEditItem({} as Driver);
    }

    const [spinTip, setSpinTip] = useState("Loading...");

    const headerContent = () => <>
        <Row>
            <Col span={23}>
                {/* <Text strong>{t(TABLE_NAME)}</Text> */}
            </Col>
            <Col span={1}>
                <Button shape="round" icon={<PlusOutlined />} onClick={() => { setEditItem({ Id: "-1" } as any); form.resetFields(); }} />
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D") })
                .map((item) => {
                    if (carData.length > 0) {
                        const carInfo = carData.find((carItem: any) => { return carItem.props.value == item.CarId });
                        item["CarNumberPlate"] = carInfo ? carInfo.props.children : "";
                    }
                    return item;
                })
        }
        return undefined;
    }

    const expandedRowRender = (record: any): React.ReactNode => {
        return (
            <Row justify={"center"} style={{ backgroundColor: "ActiveCaption" }}>
                <Col span={22} style={{ marginTop: "10px" }}>
                    <FHInventory
                        filter={record.Id ? { "DriverId": record.Id } : undefined}
                        tableName={"Shuttle"}
                        expandedRowRender={(record: any) => {
                            return <>{"Shuttle History"}</>
                        }}
                    ></FHInventory>
                </Col>
            </Row>
        );
    }

    const formContent = <>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                size={"small"}
                initialValues={{}}
                onFinish={onFinish}
                ref={formRef}
            >
                <Form.Item name={"PhoneNumber"} label={t("PhoneNumber")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input onChange={(e: any) => handleSetUserName(e.target.value)} disabled={data.some((item: any) => item.Id === editItem?.Id)} />
                </Form.Item>
                <Form.Item name={"Name"} label={t("Name")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Surname"} label={t("Surname")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"UserName"} label={t("UserName")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={"Password"} label={t("Password")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={"CarId"} label={t("CarId")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Select placeholder="Araç seçin">
                        {carData}
                    </Select>
                </Form.Item>
                <Form.Item name={"Status"} label={t("Status")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Id"} label={t("Id")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Col span={6}>
                            <Button htmlType="button" style={{ margin: '0 8px', minWidth: "80px" }} onClick={() => { setEditItem({} as Driver) }}>
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button style={{ margin: '0 8px', minWidth: "80px" }} type="primary" htmlType="submit" name="submit_button"> {t("Save")} </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Col>
    </>;

    const tableContent =
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
                rowKey="Id"
                scroll={{ x: true }}
                title={headerContent}
                columns={columns}
                expandable={{
                    expandedRowRender: record => expandedRowRender(record),
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
                dataSource={getDataSource()}
            />

        </Col>;


    return (<Spin spinning={spinTip !== ""} tip={spinTip}>
        <Row justify="center" align="middle">
            {
                editItem.Id
                    ? formContent
                    : tableContent
            }
        </Row>
    </Spin>);

}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult };
};

export default connect(mapStateToProps, {
    handlePopup
})(FHDriver);
