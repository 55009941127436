
const selectedMenuItem = (state = "", action: { type: any; key: string }) => {
    switch (action.type) {
        case 'CHANGE_SELECT_MENU_ITEM':
            return action.key;
        default:
            return state
    }
}

export default selectedMenuItem;