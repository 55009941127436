import { Button, Col, Popconfirm, Row, Space, Table, Typography } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import React, { useEffect, useState } from 'react';
import { getTableItems, getTableItemWithId, insertItemOnDb, updateItemOnDb } from '../../dto/ServerHelper';
import { useTranslation } from 'react-i18next';
import { Passenger } from '../../model/Passenger';
import { ShuttlePassengerLink } from '../../model/ShuttlePassengerLink';
import FHDrawer from '../FHDrawer';
import FHSelectPassenger from './FHSelectPassenger';
import { MarkerCoordinate } from '../../model/MarkerCoordinate';
import { Shuttle } from '../../model/Shuttle';

const { Text } = Typography;

interface FHPassengersInTheShuttleProps {
    shuttle: Shuttle;
    setPassengersCoordinates?: (res: MarkerCoordinate[]) => void;
}

const FHPassengersInTheShuttle = (propsx: FHPassengersInTheShuttleProps) => {

    const [dataSource, setDataSource] = useState([] as any);
    const { t } = useTranslation();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedNewPassengers, setSelectedNewPassengers] = useState([] as Passenger[]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const TITLE = "Passenger";
    const ADD_PASSENGER = "Ekle";
    const REMOVE_PASSENGER = "Çıkar";

    const columns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 30,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        { "title": t("PassengerName"), "dataIndex": "PassengerName", "key": "PassengerName" },
        { "title": t("Address"), "dataIndex": "Address", "key": "Address" },
        { "title": t("UserName"), "dataIndex": "UserName", "key": "UserName" },
        { "title": t("Password"), "dataIndex": "Password", "key": "Password" },
        { "title": t("Guardian"), "dataIndex": "Guardian", "key": "Guardian" },
        { "title": t("GuardianNumber"), "dataIndex": "GuardianNumber", "key": "GuardianNumber" },
        { "title": t("Organisation"), "dataIndex": "Organisation", "key": "Organisation" },
        { "title": t("Latitude"), "dataIndex": "Latitude", "key": "Latitude" },
        { "title": t("Longitude"), "dataIndex": "Longitude", "key": "Longitude" },
        { "title": t("IsLogedin"), "dataIndex": "IsLogedin", "key": "IsLogedin" },
        { "title": t("Id"), "dataIndex": "Id", "key": "Id" },
    ] as any;


    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (propsx.setPassengersCoordinates && dataSource) {
            propsx.setPassengersCoordinates(dataSource.map((val: Passenger) => ({ Id: val.Id, Info: val.PassengerName, Latitude: val.Latitude, Longitude: val.Longitude } as MarkerCoordinate)));
        }
    }, [dataSource]);

    const loadData = () => {
        const passengersId: string[] = propsx.shuttle.Passengers.split(",");
        passengersId.forEach(passengerId => {

            getTableItemWithId<Passenger>("Passenger", passengerId).then((passengerResult: Passenger) => {
                passengerResult.Id = passengerId;
                setDataSource((oldArray: any) => [...oldArray, passengerResult]);
            });

        });
    }

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const SortableItem = SortableElement((props: any) => <tr {...props} />);
    const SortableContainerFH = SortableContainer((props: any) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter((el: any) => !!el);

            // eslint-disable-next-line prefer-const
            let passengers: string[] = [];
            newData.forEach((element: Passenger) => {
                passengers.push(element.Id)
            });

            propsx.shuttle.Passengers = passengers.join(",");

            updateItemOnDb("Shuttle", propsx.shuttle, propsx.shuttle.Id);

            setDataSource(newData);
        }
    };

    const DraggableContainer = (props: any) => (
        <SortableContainerFH
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }: any) => {
        const index = dataSource.findIndex((x: Passenger) => x.Id === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const removePassenger = () => {
        selectedRowKeys.forEach((key: any) => {

            // eslint-disable-next-line prefer-const
            let passengers = propsx.shuttle.Passengers.split(",").filter(item => item !== key);
            propsx.shuttle.Passengers = passengers.join(",");

            updateItemOnDb("Shuttle", propsx.shuttle, propsx.shuttle.Id);
        })

        // eslint-disable-next-line prefer-const
        let newDataSource = dataSource.filter((item: Passenger) => selectedNewPassengers.some(k => k.Id === item.Id) === false);

        setDataSource(newDataSource);
    }

    const headerContent = () => <>
        <Row>
            <Col span={22}>
                <Text strong>{t(TITLE)}</Text>
            </Col>
            <Col span={2}>
                <Space>
                    <Text underline onClick={() => setDrawerVisible(true)}>{t(ADD_PASSENGER)}</Text>
                    <Popconfirm placement="top" title={"İşlem gerçekleştirilecektir. Emin misiniz?"} onConfirm={removePassenger} okText="Yes" cancelText="No">
                        <Text underline>{t(REMOVE_PASSENGER)}</Text>
                    </Popconfirm>
                </Space>
            </Col>
        </Row>
    </>;

    const onOk = () => {
        // eslint-disable-next-line prefer-const
        let passengers = propsx.shuttle.Passengers.split(",");
        selectedNewPassengers.forEach(element => {
            passengers.push(element.Id)
        });

        propsx.shuttle.Passengers = passengers.join(",");

        updateItemOnDb("Shuttle", propsx.shuttle, propsx.shuttle.Id);

        setDataSource((oldArray: any) => [...oldArray, ...selectedNewPassengers]);
        setDrawerVisible(false);
    }

    const onCancel = () => {
        setDrawerVisible(false);
    }

    const onSelectChange = (selectedRowKeys: any) => {
        setSelectedRowKeys(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <>
            <Table
                rowSelection={rowSelection}
                title={headerContent}
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                rowKey="Id"
                components={dataSource && {
                    body: {
                        wrapper: DraggableContainer,
                        row: DraggableBodyRow,
                    },
                }}
                footer={() => ''}
                scroll={{ x: true }}
            />
            <FHDrawer
                zIndex={999999999}
                content={<FHSelectPassenger onChange={(passengers: Passenger[]) => setSelectedNewPassengers(passengers)}></FHSelectPassenger>}
                visible={drawerVisible}
                onClose={onCancel}
                title={t(ADD_PASSENGER)}
                bodyStyle={{ paddingBottom: 80 }}
                width={720}
                extra={
                    <Space>
                        <Button onClick={onCancel}>Vazgeç</Button>
                        <Button onClick={onOk} type="primary">
                            Kaydet
                        </Button>
                    </Space>
                }
            ></FHDrawer>
        </>
    );
}

export default FHPassengersInTheShuttle;

