import { MarkerCoordinate } from "../../model/MarkerCoordinate"
import { Product } from "../../model/Product"
import { School } from "../../model/School"
import { User } from "../../model/User"


export const addTodo = (todo: any) => ({
    type: 'CHANGE_CONTENT',
    text: todo
})

export const openDrawer = (visible: any) => ({
    type: 'OPEN_DRAWER',
    visible: visible
})

export const addToBasket = (product: Product) => ({
    type: 'ADD_TO_BASKET',
    product: product
})

export const loginUser = (user: User) => ({
    type: 'LOGIN_USER',
    user: user
})

export const exitUser = () => ({
    type: 'EXIT_USER',
    user: {
        Id: "0",
        Name: undefined,
    } as unknown as User
})

export const handlePopup = (visible: boolean) => ({
    type: 'HANDLE_POPUP',
    handleOk: visible
})

export const changePassengerCoordinate = (coordinate: MarkerCoordinate) => ({
    type: 'CHANGE_PASSENGER_COORDINATE',
    coordinate: coordinate
})

export const changeSelectedMenuItem = (key: string) => ({
    type: 'CHANGE_SELECT_MENU_ITEM',
    key: key
})

export const setSchool = (school: School) => ({
    type: 'SET_SCHOOL',
    school: school,
})