import { combineReducers } from 'redux'
import todos from './todos';
import drawer from './drawer';
import user from './user';
import popupResult from './popupResult';
import homeProduct from './homeProduct';
import passengerCoordinate from './passengerCoordinate';
import selectedMenuItem from './selectedMenuItem';
import school from './school';

export default combineReducers({
    todos,
    drawer,
    user,
    popupResult,
    homeProduct,
    passengerCoordinate,
    selectedMenuItem,
    school,
});