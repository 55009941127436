import { Row, Col, Descriptions, Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import FHPassengersInTheShuttle from './FHPassengersInTheShuttle';
import FHMapApp from '../Map/FHMapApp';
import { Shuttle } from '../../model/Shuttle';
import { useTranslation } from 'react-i18next';
import { MarkerCoordinate } from '../../model/MarkerCoordinate';
import { getTableItemsWithPath, getTableItemWithId, updateItemOnDb, updateItemOnDbWithPath } from '../../dto/ServerHelper';
import { ShuttleBaseTrackRoute } from '../../model/ShuttleBaseTrackRoute';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { School } from '../../model/School';
import { LeftOutlined } from '@ant-design/icons';
import { Driver } from '../../model/Driver';

const { TabPane } = Tabs;

interface FHShuttleDetailProps {
    shuttle: Shuttle;
    school: School;
    setSelectedShuttle: any;
    driverInfo: Driver;
}

const FHShuttleDetail = (props: FHShuttleDetailProps) => {
    const { t } = useTranslation();
    const [passengersCoordinates, setPassengersCoordinates] = useState([] as MarkerCoordinate[]);
    const [baseTrackRoute, setBaseTrackRoute] = useState(undefined as unknown as ShuttleBaseTrackRoute);

    useEffect(() => {
        getTableItemWithId<ShuttleBaseTrackRoute>("ShuttleBaseTrackRoute", props.shuttle.Id).then((response: ShuttleBaseTrackRoute) => setBaseTrackRoute(response));
    }, []);

    const saveShuttleBaseRoute = (calculatedRoute: ShuttleBaseTrackRoute) => {
        calculatedRoute.ShuttleId = props.shuttle.Id;
        updateItemOnDb("ShuttleBaseTrackRoute", calculatedRoute, calculatedRoute.ShuttleId);
    }

    return (
        <Row justify='center'>
            <Row >
                <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                    <Button
                        type="ghost"
                        icon={<LeftOutlined />}
                        onClick={() => props.setSelectedShuttle(undefined)}
                        size='small'
                    >
                        Geri
                    </Button>
                </Col>
                <Col xs={20} sm={20} md={18} lg={14} xl={14}>
                    <Row>
                        <Descriptions layout="horizontal">
                            <Descriptions.Item label={t("OrganisationName")}>{props.school.Name}</Descriptions.Item>
                            <Descriptions.Item label={t("ShuttleName")}>{props.shuttle.ShuttleName}</Descriptions.Item>
                            <Descriptions.Item label={t("CarNumberPlate")}>{props.shuttle.CarNumberPlate}</Descriptions.Item>
                            <Descriptions.Item label={t("ShuttleTime")}>{props.shuttle.ShuttleTime}</Descriptions.Item>
                            <Descriptions.Item label={t("Direction")}>{props.shuttle.Direction}</Descriptions.Item>
                            <Descriptions.Item label={t("PhoneNumber")}>{props.driverInfo.PhoneNumber}</Descriptions.Item>
                            <Descriptions.Item label={t("Distance")}>{baseTrackRoute ? baseTrackRoute.Distance : ""}</Descriptions.Item>
                            <Descriptions.Item label={t("Route")}>{props.shuttle.Route}</Descriptions.Item>
                            <Descriptions.Item label={t("DriverName")}>{props.driverInfo.Name}</Descriptions.Item>
                            <Descriptions.Item label={t("Duration")}>{baseTrackRoute ? baseTrackRoute.Duration : ""}</Descriptions.Item>
                        </Descriptions>
                    </Row>
                    <Row>
                        {/* <Button block onClick={() => { updateOneShuttleTimes(props.shuttle.Id) }}>Başlangıç bitiş saatlerini güncelle</Button>
                        <Button block onClick={() => { orderPassengerFromRouteForOneShuttle(props.shuttle.Id, setNewOrder); }}>Rotaya ve "Bindi" bilgisine göre öğrenci sıralaması</Button> */}
                        {/* <Button block onClick={() => { updatePassengerCoordinates(props.shuttle.Id, "20221223") }}>Servisteki öğrencilerin konumunu rotaya göre güncelle</Button> */}
                    </Row>
                </Col>
            </Row>

            <Row justify='center' style={{ width: "100%", height: "100%", margin: "50px" }}>
                <Col span={24} style={{ width: "100%", height: "100%" }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Yolcular" key="1">
                            <FHPassengersInTheShuttle
                                shuttle={props.shuttle}
                                setPassengersCoordinates={(res: MarkerCoordinate[]) => setPassengersCoordinates(res)}
                            ></FHPassengersInTheShuttle>
                        </TabPane>
                        <TabPane tab="Harita (Rota işlemleri)" key="2" style={{ width: "100%", height: "100%" }}>
                            <FHMapApp
                                baseTrackRoute={baseTrackRoute}
                                saveShuttleBaseRoute={saveShuttleBaseRoute} passengerCoordinates={passengersCoordinates}
                                isCalculateRoute={true}
                                shuttle={props.shuttle}
                            ></FHMapApp>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Row>
    );
}

const mapStateToProps = (state: any) => {
    const school = state.school;
    return { school };
};

export default connect(mapStateToProps, {
})(FHShuttleDetail);