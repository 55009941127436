import './App.css';
import React, { Suspense, useState } from 'react';
import { Row, Col, Layout, Divider } from 'antd';
import 'antd/dist/antd.min.css';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from "react-router-dom";
import RegisterPage from './pages/RegisterPage';
import PrivateRoute from './components/base/PrivateRoute';
import FHPassenger from './components/administration-modules/FHPassenger';
import FHShuttleTable from './components/administration-modules/FHShuttleTable';
import FHCar from './components/administration-modules/FHCar';
import FHDriver from './components/administration-modules/FHDriver';
import Login from './components/Login';
import BBSchool from './components/administration-modules/BBSchool';
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import PrivacyParent from './pages/PrivacyParent';
import PrivacyDriver from './pages/PrivacyDriver';
import ApproveLocation from './pages/ApproveLocation';
import UserContext from './Context/User';
import { connect } from 'react-redux';
import UploadData from './pages/UploadData';
import PrivacyManagment from './pages/PrivacyManagment';

const { Footer } = Layout;

function App(props: any) {

  const [user, setUser] = useState(props.user as any);

  return (
    <div className="App">
      <Suspense fallback={null}>
        <Router>
          <UserContext.Provider value={[user, setUser]}>
            <Route path="/privacy-managment" exact component={PrivacyManagment} />
            <Route path="/privacy-veli" exact component={PrivacyParent} />
            <Route path="/privacy-surucu" exact component={PrivacyDriver} />
            <Route path="/sign-up" exact component={SignUp} />
            <Route path="/sign-in" exact component={SignIn} />
            <Main>
              <PrivateRoute exact path="/dashboard" component={Home} />
              <PrivateRoute exact path="/shuttle" component={FHShuttleTable} />
              <PrivateRoute exact path="/passenger" component={FHPassenger} />
              <PrivateRoute exact path="/car" component={FHCar} />
              <PrivateRoute exact path="/driver" component={FHDriver} />
              <PrivateRoute exact path="/school" component={BBSchool} />
              <PrivateRoute exact path="/approvelocation" component={ApproveLocation} />
              <PrivateRoute exact path="/uploaddata" component={UploadData} />
              <PrivateRoute exact path="/tables" component={Tables} />
              <PrivateRoute exact path="/billing" component={Billing} />
              <PrivateRoute exact path="/profile" component={Profile} />
              <Redirect from="*" to="/dashboard" />
            </Main>
          </UserContext.Provider>
        </Router>
      </Suspense>
    </div>
  );

}
const mapStateToProps = (state: any) => {
  const user = state.user;
  return { user };
};

export default connect(mapStateToProps)(App);