import { Drawer, DrawerProps } from 'antd';
import React from 'react';



const NotFoundActiveShuttle = (props: any) => {
    return (
        <>
            <img src={require('../../assets/images/active-bus-notfound.png')} />
            <p>Aktif servis bulunamadı!</p>
        </>
    );
}

export default NotFoundActiveShuttle;