

import { message } from "antd";
import { ref, get, child, query, update, push, set, remove } from "firebase/database";
import { ShuttleRoute } from "../model/ShuttleRoute";
import { database } from './FireBaseDB';

export async function getUser(nickName: string, password: string, loginType: number): Promise<any> {
    let result = undefined;

    const dbRef = ref(database);

    await get(child(dbRef, `User/${nickName}`)).then((snapshot) => {

        // eslint-disable-next-line prefer-const
        let snapshotValue = snapshot.val();
        if (snapshotValue["Password"] == password && snapshotValue["LoginType"] == loginType) {
            result = snapshotValue;
        }
    });

    return result;
}

export async function getTableItemWithId<T>(tableName: string, id: string): Promise<T> {
    let result = undefined;
    const dbRef = ref(database);
    await get(child(dbRef, `${tableName}/${id}`)).then((snapshot) => {
        result = snapshot.val();
        result.Id = id;
        return result;
    });
    return result as unknown as Promise<T>;
}

export async function getTableItemCount(tableName: string): Promise<number> {
    let result = undefined;
    const dbRef = ref(database);
    await get(child(dbRef, `${tableName}`)).then((snapshot) => {
        result = snapshot.size
        return result;
    });
    return result as unknown as Promise<number>;
}

export async function getTableItemWithIdasync<T>(tableName: string, id: string): Promise<T> {
    let result = undefined;
    const dbRef = ref(database);
    get(child(dbRef, `${tableName}/${id}`)).then((snapshot) => {
        result = snapshot.val();
        return result;
    });
    return result as unknown as Promise<T>;
}

export async function getTableItems(tableName: string): Promise<any> {
    let result = undefined;

    await get(query(ref(database, tableName))).then((snapshot: any) => {
        result = [];
        // eslint-disable-next-line prefer-const
        let json = snapshot.val();

        // eslint-disable-next-line prefer-const
        for (let key in json) {
            // eslint-disable-next-line prefer-const
            let keyTemp = json[key];
            keyTemp["Id"] = key;
            result.push(keyTemp);
        }
    });

    return result;
}

export async function insertItemOnDb(tableName: string, item: any) {
    let uniqueKey: any = push(child(ref(database), tableName)).key;
    if (uniqueKey === null)
        uniqueKey = undefined;
    return updateItemOnDb(tableName, item, uniqueKey);
}

export async function updateItemOnDb(tableName: string, item: any, key: string) {
    const updates = {} as any;
    let uniqueKey = key ? key : item.Id;

    if (!uniqueKey) {
        uniqueKey = push(child(ref(database), tableName)).key;
    }

    const { Id, ...newObj } = item;
    updates[`/${tableName}/${uniqueKey}`] = newObj;

    return update(ref(database), updates);
}

export async function deleteItemOnDb(path: string | undefined) {
    const databaseRef = ref(database, path);
    return remove(databaseRef)
}

export async function getTableItemsWithPath(path: string): Promise<any> {
    let result = undefined;
    const dbRef = ref(database);
    result = (await get(child(dbRef, path))).val();
    return result as unknown as Promise<any>;
}

export async function updateItemOnDbWithPath(path: string, item: any) {
    const updates = {} as any;
    updates[path] = item;
    return update(ref(database), updates);
}