/* eslint-disable prefer-const */

import Polygon from "ol/geom/Polygon";


let points: any[] = [],
    msg_el = document.getElementById('msg'),
    url_osrm_nearest = '//router.project-osrm.org/nearest/v1/driving/',
    url_osrm_route = '//router.project-osrm.org/route/v1/driving/';


// map.on('click', function(evt){
//   utils.getNearest(evt.coordinate).then(function(coord_street){
//     var last_point = points[points.length - 1];
//     var points_length = points.push(coord_street);

//     utils.createFeature(coord_street);

//     if (points_length < 2) {
//       msg_el.innerHTML = 'Click to add another point';
//       return;
//     }

//     //get the route
//     var point1 = last_point.join();
//     var point2 = coord_street.join();

//     fetch(url_osrm_route + point1 + ';' + point2).then(function(r) { 
//       return r.json();
//     }).then(function(json) {
//       if(json.code !== 'Ok') {
//         msg_el.innerHTML = 'No route found.';
//         return;
//       }
//       msg_el.innerHTML = 'Route added';
//       //points.length = 0;
//       utils.createRoute(json.routes[0].geometry);
//     });
//   });
// });

const utils = {
    getNearest: function (coord: any) {
        return new Promise(function (resolve, reject) {
            //make sure the coord is on street
            fetch(url_osrm_nearest + coord.join()).then(function (response) {
                // Convert to JSON
                return response.json();
            }).then(function (json) {
                if (json.code === 'Ok') resolve(json.waypoints[0].location);
                else reject();
            });
        });
    },
    getRoute: function (point1: string, point2: string) {
        return new Promise(function (resolve, reject) {
            fetch(url_osrm_route + point1 + ';' + point2).then(function (r) {
                return r.json();
            }).then(function (json) {
                if (json.code !== 'Ok') {
                    // msg_el.innerHTML = 'No route found.';
                    return;
                }
                // msg_el.innerHTML = 'Route added';
                //points.length = 0;
                // utils.createRoute(json.routes[0].geometry);
                return json.routes[0].geometry;
            });
        });
    },
    // createRoute: function (polyline: any) {
    //     // route is ol.geom.LineString
    //     var route = new MultiLineString({
    //         factor: 1e5
    //     }).readGeometry(polyline, {
    //         dataProjection: 'EPSG:4326',
    //         featureProjection: 'EPSG:3857'
    //     });
    //     var feature = new ol.Feature({
    //         type: 'route',
    //         geometry: route
    //     });
    //     return
    // },
}

export default utils;