import { Badge, Button, Dropdown, Form, Input, Popconfirm, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, getTableItemWithId, getTableItemWithIdasync, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import FHMapApp from '../Map/FHMapApp';
import { Passenger } from '../../model/Passenger';
import { MarkerCoordinate } from '../../model/MarkerCoordinate';
import UserContext from '../../Context/User';

const { Text } = Typography;

interface FHPassengerProps {
    onChange: any;
}

function FHSelectPassenger(props: FHPassengerProps) {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [editItem, setEditItem] = useState({} as Passenger);
    const [spinTip, setSpinTip] = useState("Loading...");
    const [user] = useContext(UserContext);

    const columns = [
        { "title": t("PassengerName"), "dataIndex": "PassengerName", "key": "PassengerName" },
        { "title": t("Address"), "dataIndex": "Address", "key": "Address" },
        { "title": t("Guardian"), "dataIndex": "Guardian", "key": "Guardian" },
        { "title": t("GuardianNumber"), "dataIndex": "GuardianNumber", "key": "GuardianNumber" },
        { "title": t("Organisation"), "dataIndex": "Organisation", "key": "Organisation" },
    ] as any;


    useEffect(() => {
        getTableItems("Passenger").then((result: Passenger[]) => {

            const items = result.filter((passengerItem) => {
                return passengerItem.Status !== "D";
            });

            setData(items);

            setSpinTip("");
        });
    }, []);

    const headerContent = () => <>
        <Row>
            <Col span={23}>
                {/* <Text strong>{t("Passenger")}</Text> */}
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D" && user.Organization == item.Organisation) });
        }
        return undefined;
    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
            props.onChange(selectedRows);
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    return (
        <Spin spinning={spinTip !== ""} tip={spinTip}>
            <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        rowKey="Id"
                        scroll={{ x: true }}
                        title={headerContent}
                        columns={columns}
                        dataSource={getDataSource()}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {
                        <FHMapApp
                            shuttleRoute={[]}
                            passengerCoordinates={
                                (editItem && editItem.Longitude)
                                    ? [{ Id: "0", Latitude: editItem.Latitude, Longitude: editItem.Longitude } as MarkerCoordinate]
                                    : []}
                        ></FHMapApp>
                    }
                </Col>
            </Row>
        </Spin>
    );
}

const mapStateToProps = (state: any) => {
    return {};
};

export default connect(mapStateToProps, {
    handlePopup
})(FHSelectPassenger);