import { Drawer, DrawerProps } from 'antd';
import React from 'react';

interface FHDrawerProps extends DrawerProps {
    content: React.ReactNode
}

const FHDrawer = (props: FHDrawerProps) => {
    return (
        <Drawer {...props}>
            {props.content}
        </Drawer>
    );
}

export default FHDrawer;