
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Row, Col, Card, Alert, message } from 'antd'
import { Shuttle } from '../../model/Shuttle';
import { ActiveShuttle } from '../../model/ActiveShuttle';
import { getTableItems, getTableItemWithId } from '../../dto/ServerHelper';
import moment from 'moment';
import { RightCircleOutlined } from '@ant-design/icons';
import { DataSnapshot, onChildAdded, onChildChanged, ref } from 'firebase/database';
import { database } from '../../dto/FireBaseDB';
import NotFoundActiveShuttle from './notFoundActiveShuttle';
import { PassengerShuttleAction } from '../../model/PassengerShuttleAction';
import Meta from 'antd/lib/card/Meta';
import UserContext from '../../Context/User';

const ActiveShuttleInfo = (props: any) => {

    const [listOfActiveShuttle, setListOfActiveShuttle] = useState([] as any[]);
    const [user, setUser] = useContext(UserContext);

    useEffect(() => {

        const currentDate = moment(new Date()).format('YYYYMMDD');
        const path = `/ActiveShuttle/${currentDate}`;

        onChildAdded(ref(database, path), (snapshot: DataSnapshot) => {
            const activeShuttleResult = snapshot.val();

            if (activeShuttleResult) {
                const keyOfObj = Object.keys(activeShuttleResult);
                keyOfObj.forEach((element: any) => {
                    const activeShuttleInfo = activeShuttleResult[element] as ActiveShuttle;
                    if (activeShuttleInfo && activeShuttleInfo.IsActive) {

                        console.log(listOfActiveShuttle)
                        console.log(activeShuttleInfo)

                        const isExist = listOfActiveShuttle.filter(item => item.ShuttleInfo.Id === activeShuttleInfo.ShuttleId);

                        if (isExist.length === 0) {
                            getTableItemWithId<Shuttle>("Shuttle", activeShuttleInfo.ShuttleId).then((result: Shuttle) => {
                                if (result.Organisation === user.Organization.toString()) {
                                    result.Id = activeShuttleInfo.ShuttleId;
                                    setListOfActiveShuttle(oldArray => [...oldArray, { PassengerCount: 16, GotOn: 0, DidNotRide: 0, ShuttleInfo: result }]);
                                }
                            }).catch(() => {
                                return false;
                            });
                        }
                    }
                });
            }

        });

        onChildChanged(ref(database, path), (snapshot) => {

            const activeShuttleResult = snapshot.val();

            if (activeShuttleResult) {
                const keyOfObj = Object.keys(activeShuttleResult);
                keyOfObj.forEach((element: any) => {
                    const activeShuttleInfo = activeShuttleResult[element] as ActiveShuttle;
                    if (activeShuttleInfo && !activeShuttleInfo.IsActive) {
                        const isExist = listOfActiveShuttle.filter(item => item.ShuttleInfo.Id !== activeShuttleInfo.ShuttleId);

                        setListOfActiveShuttle(isExist);
                    }
                });
            }

        });

    }, []);


    return (
        <Row style={{ maxHeight: 650, width: "100%", overflowY: 'auto' }}>
            <Col style={{ width: "100%", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
                {
                    listOfActiveShuttle.length === 0 ? <NotFoundActiveShuttle></NotFoundActiveShuttle> : listOfActiveShuttle.map(item =>
                        <Card key={item.ShuttleInfo.ShuttleName} style={{ overflowY: 'auto', textAlign: "center" }} >
                            <Meta
                                title={`${item.ShuttleInfo.ShuttleName} - ${item.ShuttleInfo.Direction}`}
                                description={`${item.ShuttleInfo.CarNumberPlate} - ${item.ShuttleInfo.DriverId}`}
                            />
                        </Card>
                    )
                }
            </Col>
        </Row >
    );
}

export default ActiveShuttleInfo;