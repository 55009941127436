import React, { useRef, useState, useEffect } from "react"
import "./FHMap.css";
import * as ol from "ol";
import FHMapContext from "./FHMapContext";

const FHMap = ({ children, zoom, center }: any) => {
    const mapRef = useRef();
    const [map, setMap] = useState(null as any);

    // on component mount
    useEffect(() => {
        // eslint-disable-next-line prefer-const
        let options = {
            view: new ol.View({ zoom, center }),
            layers: [],
            controls: [],
            overlays: []
        };
        // eslint-disable-next-line prefer-const
        let mapObject = new ol.Map(options);
        mapObject.setTarget(mapRef.current);
        setMap(mapObject);

        return () => mapObject.setTarget(undefined);
    }, []);
    // zoom change handler
    useEffect(() => {
        if (!map) return;
        map.getView().setZoom(zoom);
    }, [zoom]);
    // center change handler
    useEffect(() => {
        if (!map) return;
        map.getView().setCenter(center)
    }, [center])
    return (
        <FHMapContext.Provider value={{ map } as any}>
            <div ref={mapRef as any} style={{ width: "100%", height: "100%" }}>
                {children}
            </div>
            <div id="popup" style={{ backgroundColor: "white" }}>
                <a href="#" id="popup-closer"></a>
                <div id="popup-content"></div>
            </div>

        </FHMapContext.Provider>
    )
}
export default FHMap;