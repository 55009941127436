
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'


const PrivateRoute = ({ component: Component, ...rest }: any) => {

    const isLoggedIn = rest && rest.user !== undefined && rest.user.Name !== undefined && rest.user.Name !== "";

    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
                )
            }
        />
    )
}

const mapStateToProps = (state: any) => {
    const user = state.user;
    const school = state.school;
    return { user, school };
};

export default connect(mapStateToProps)(PrivateRoute);
