
import { Badge, Button, Checkbox, Dropdown, Form, FormInstance, Input, Popconfirm, Row, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, getTableItemWithId, getTableItemWithIdasync, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import FHMapApp from '../Map/FHMapApp';
import { Passenger } from '../../model/Passenger';
import { MarkerCoordinate } from '../../model/MarkerCoordinate';
import { PassengerBase } from '../../model/PassengerBase';
import { Shuttle } from '../../model/Shuttle';
import { Select } from 'antd';
import { School } from '../../model/School';

import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import UserContext from '../../Context/User';

const { Option } = Select;

interface FHPassengerProps {
    id?: any;
    handlePopup: any;
    onClickListItem?: any;
    filter?: any;
    passengerCoordinate?: MarkerCoordinate;
    school: School;

}

function FHPassenger(props: FHPassengerProps) {

    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [schoolData, setSchoolData] = useState([] as any[]);
    const [nestedData, setNestedData] = useState({} as any);
    const [isLoading, setIsLoading] = useState({} as any);
    const [editItem, setEditItem] = useState({} as Passenger);
    const [userName, setUserName] = useState("");

    const searchInput = useRef<any>(null);
    const [user, setUser] = useContext(UserContext);
    // eslint-disable-next-line prefer-const
    let formRef = React.createRef<FormInstance>();

    const turkishtoEnglish = (value: string) => {
        const Maps: any = {
            "İ": "I", "Ş": "S", "Ç": "C", "Ğ": "G", "Ü": "U", "Ö": "O",
            "ı": "i", "ş": "s", "ç": "c", "ğ": "g", "ü": "u", "ö": "o"
        };
        Object.keys(Maps).forEach(Old => {

            value = value.replace(Old, Maps[Old]);
        });
        return value;
    };

    const handleSetUserName = (value: string) => {
        if (!isPassengerExist(editItem?.Id)) {
            // eslint-disable-next-line prefer-const
            let splitName = turkishtoEnglish(value.toUpperCase()).split(" ");
            // eslint-disable-next-line prefer-const
            let lastChar = splitName[1] ? splitName[1].substr(0, 1) : '';
            // eslint-disable-next-line prefer-const
            let userName = splitName[0] + lastChar + Math.floor(Math.random() * 9000 + 10).toString();
            formRef.current!.setFieldsValue({ UserName: userName });
            formRef.current!.setFieldsValue({ Password: Math.floor(Math.random() * 9000 + 10).toString() });
        }
    }

    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void, confirm: any, dataIndex: string) => {
        clearFilters();
        handleSearch(confirm)
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t(dataIndex)}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filtrele
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Temizle
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        render: (text: any) => text,
    });

    const columns = [
        {
            title: t('Action'),
            dataIndex: '',
            key: 'x',
            render: (_: any, record: any) =>
                <>
                    {<a onClick={() => setEditItem(record)}>Detay</a>}
                    <br></br>
                    {<Popconfirm title="Silmek istediğinizden emin misiniz?" onConfirm={() => { record.Status = "D"; saveChanges(record); }}>
                        <a>Sil</a>
                    </Popconfirm>}
                </>
            ,
        },
        {
            "title": t("PassengerName"),
            "dataIndex": "PassengerName",
            "key": "PassengerName",
            ...getColumnSearchProps('PassengerName'),
        },
        {
            "title": t("Address"),
            "dataIndex": "Address",
            "key": "Address"
        },
        {
            "title": t("OrganisationName"),
            "dataIndex": "OrganisationName",
            "key": "OrganisationName",
            ...getColumnSearchProps('OrganisationName'),
        },

    ] as any;

    useEffect(() => {
        getTableItems("Passenger").then((result: Passenger[]) => {

            const items = result.filter((passengerItem) => {

                let isEvery = true;
                if (props.filter) {
                    isEvery = Object.keys(props.filter).every((filterItem: string) => {
                        return props.filter[filterItem] === (passengerItem as any)[filterItem]
                    });
                }

                return passengerItem.Status !== "D" && isEvery && passengerItem.Organisation === user.Organization.toString();
            });

            setData(items);

            setSpinTip("");
            setSpinTip("");
        });

    }, []);

    useEffect(() => {
        form.setFieldsValue(editItem);
    }, [editItem]);

    useEffect(() => {
        // eslint-disable-next-line prefer-const
        let newValues = { ...editItem };
        newValues.Latitude = props.passengerCoordinate!.Latitude;
        newValues.Longitude = props.passengerCoordinate!.Longitude;
        setEditItem(newValues);
    }, [props.passengerCoordinate]);

    const expandedRowRender = (record: Passenger) => {
        const columns = [
            { title: t("Organisation"), dataIndex: "Organisation", key: "Organisation" },
            { title: t("ShuttleName"), dataIndex: "ShuttleName", key: "ShuttleName" },
            { title: t("Direction"), dataIndex: "Direction", key: "Direction" },
            { title: t("DriverId"), dataIndex: "DriverId", key: "DriverId" },
        ];

        const data = nestedData[record.Id];

        return (
            <Table
                loading={isLoading[record.Id] && !data}
                columns={columns}
                dataSource={nestedData[record.Id]}
                pagination={false}
                size='small'
            />
        );
    };

    const handleExpand = (expanded: any, record: Passenger) => {

        if (record.ShuttleLink && record.ShuttleLink.length > 0) {
            setIsLoading({
                [record.Id]: true
            });
            let counter = 0;
            record.ShuttleLink.forEach((element: { ShuttleId: string; }) => {
                getTableItemWithId<Shuttle>("Shuttle", element.ShuttleId).then((shuttleResult: Shuttle) => {
                    shuttleResult && setNestedData((state: any) => ({
                        ...state,
                        [record.Id]: [
                            {
                                Id: record.Id,
                                Organisation: record.Organisation,
                                ShuttleName: shuttleResult.ShuttleName,
                                Direction: shuttleResult.Direction,
                                DriverId: shuttleResult.DriverId,
                            }
                        ]
                    }));
                }).finally(() => {
                    counter++;

                    if (record.ShuttleLink?.length === counter) {
                        setIsLoading({
                            [record.Id]: false
                        });
                    }
                });
            });
        }
    };

    const isPassengerExist = (id: string) => {
        return data.some((item: any) => item.Id === id);
    }

    const saveChanges = (changedItem: any) => {
        let result = {} as any[];

        changedItem.Organisation = user.Organization.toString();

        if (isPassengerExist(changedItem.Id)) {
            result = data.map(item => (item.Id === changedItem.Id ? changedItem : item));
            setData(result);
        } else {
            changedItem.Id = changedItem.UserName;
            changedItem.Status = "N";
            setData(state => [...state, changedItem]);
        }

        delete changedItem["OrganisationName"];
        updateItemOnDb("Passenger", changedItem as PassengerBase, changedItem.Id);
        setEditItem({} as Passenger);
        form.resetFields();
    }

    const [spinTip, setSpinTip] = useState("Loading...");

    const headerContent = () => <>
        <Row>
            <Col span={23}>
                {/* <Text strong>{t("Passenger")}</Text> */}
            </Col>
            <Col span={1}>
                <Button shape="round" icon={<PlusOutlined />} onClick={() => { setEditItem({ Id: -1, Latitude: 36.1492181874959, Longitude: 36.191523848423586 } as any); form.resetFields(); }} />
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D") })
                .map((item) => {
                    if (schoolData.length > 0) {
                        const schoolInfo = schoolData.find((schoolItem: any) => { return schoolItem.props.value == item.Organisation });
                        item["OrganisationName"] = schoolInfo ? schoolInfo.props.children : "";
                    }
                    return item;
                })
        }
        return undefined;
    }

    const onFinish = (values: any) => {
        saveChanges(values);
    }

    const passengerMapContent =
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            {
                <FHMapApp
                    isInteractionsOpen={true}
                    passengerCoordinates={
                        (editItem && editItem.Longitude)
                            ? [{ Id: editItem.Id, Info: editItem.PassengerName, Latitude: editItem.Latitude, Longitude: editItem.Longitude } as MarkerCoordinate]
                            : []}
                ></FHMapApp>
            }
        </Col>;

    const tableContent = <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Table
            rowKey="Id"
            scroll={{ x: true }}
            title={headerContent}
            columns={columns}
            expandable={{
                expandedRowRender: record => expandedRowRender(record),
                rowExpandable: record => record.Id !== 'Not Expandable',
                onExpand: handleExpand

            }}
            dataSource={getDataSource()}
        />
    </Col>;

    const formContent = <>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                size={"small"}
                initialValues={{ OrganisationName: props.school.Name, IsNotificationsEnabledFor: false }}
                onFinish={onFinish}
                ref={formRef}
            >
                <Form.Item name={"PassengerName"} label={t("PassengerName")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input onChange={(e: any) => handleSetUserName(e.target.value)} />
                </Form.Item>
                <Form.Item name={"UserName"} label={t("UserName")} rules={[{ required: false, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={"Password"} label={t("Password")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={"Address"} label={t("Address")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Guardian"} label={t("Guardian")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"GuardianNumber"} label={t("GuardianNumber")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!', }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"GuardianNumber2"} label={t("GuardianNumber2")} rules={[{ required: false, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"IsNotificationsEnabled"} label={t("IsNotificationsEnabled")} valuePropName="checked" rules={[{ required: false, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Checkbox />
                </Form.Item>
                <Form.Item name={"IsNotificationsEnabledForSecond"} label={t("IsNotificationsEnabledForSecond")} valuePropName="checked" rules={[{ required: false, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Checkbox />
                </Form.Item>
                <Form.Item name={"OrganisationName"} label={t("OrganisationName")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input disabled />
                </Form.Item>
                <Form.Item name={"ShuttleId"} label={t("ShuttleId")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"ClassName"} label={t("ClassName")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Comment"} label={t("Comment")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"IsLogedin"} label={t("IsLogedin")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Latitude"} label={t("Latitude")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Longitude"} label={t("Longitude")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Id"} label={t("Id")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Col span={6}>
                            <Button htmlType="button" style={{ margin: '0 8px', minWidth: "80px" }} onClick={() => { setEditItem({} as Passenger) }}>
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button style={{ margin: '0 8px', minWidth: "80px" }} type="primary" htmlType="submit" name="submit_button"> {t("Save")} </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Col>
    </>;

    return (
        <Spin spinning={spinTip !== ""} tip={spinTip}>
            <Row justify="center">
                {editItem.Id
                    ? <> {formContent} {passengerMapContent} </>
                    : tableContent}
            </Row>
        </Spin>
    );
}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    const passengerCoordinate = state.passengerCoordinate;
    const school = state.school;
    return { popupResult, passengerCoordinate, school };
};

export default connect(mapStateToProps, {
    handlePopup
})(FHPassenger);