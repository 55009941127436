import { useContext, useEffect } from "react";
import OLTileLayer from "ol/layer/Tile";
import MapContext from "../FHMapContext";
const TileLayer = ({ source, zIndex = 0 }: any) => {
    const { map }: any = useContext(MapContext);
    useEffect(() => {
        if (!map) return;

        // eslint-disable-next-line prefer-const
        let tileLayer = new OLTileLayer({
            source,
            zIndex,
        });
        map.addLayer(tileLayer);
        tileLayer.setZIndex(zIndex);
        return () => {
            if (map) {
                map.removeLayer(tileLayer);
            }
        };
    }, [map]);
    return null;
};
export default TileLayer;