

import { Button, Form, Input, Popconfirm, Row, Table } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Car } from '../../model/Car';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import UserContext from '../../Context/User';

const { Text } = Typography;

interface FHCarProps {
    id?: any;
    onClickListItem?: any;
    filter?: any;
}

function FHCar(props: FHCarProps) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [editItem, setEditItem] = useState({} as Car);
    const [user, setUser] = useContext(UserContext);

    const TABLE_NAME = "Car";

    const searchInput = useRef<any>(null);

    const handleSearch = (
        confirm: (param?: FilterConfirmProps) => void,
    ) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void, confirm: any, dataIndex: string) => {
        clearFilters();
        handleSearch(confirm)
    };

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={t(dataIndex)}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filtrele
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, dataIndex)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Temizle
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        render: (text: any) => text,
    });



    const columns = [
        {
            "title": t("CarNumberPlate"),
            "dataIndex": "CarNumberPlate",
            "key": "CarNumberPlate",
            ...getColumnSearchProps('CarNumberPlate'),
        },
        {
            "title": t("KM"),
            "dataIndex": "KM",
            "key": "KM"
        },
        {
            "title": t("PassengerCount"),
            "dataIndex": "PassengerCount",
            "key": "PassengerCount"
        },
        {
            "title": t("VehicleInspectionDate"),
            "dataIndex": "VehicleInspectionDate",
            "key": "VehicleInspectionDate"
        },
        {
            "title": t("InsuranceDate"),
            "dataIndex": "InsuranceDate",
            "key": "InsuranceDate"
        },
        {
            "title": t("Status"),
            "dataIndex": "Status",
            "key": "Status"
        },
        {
            "title": t("Id"),
            "dataIndex": "Id",
            "key": "Id"
        },
        {
            title: t('Action'),
            dataIndex: '',
            key: 'x',
            render: (_: any, record: any) =>
                <>
                    <Popconfirm title="Silmek istediğinizden emin misiniz?" onConfirm={() => { record.Status = "D"; saveChanges(record); }}>
                        <a>Sil</a>
                    </Popconfirm>
                    <br></br>
                    {<a onClick={() => setEditItem(record)}>Güncelle</a>}
                </>
            ,
        }
    ] as any;

    useEffect(() => {
        getTableItems(TABLE_NAME).then((result: any[]) => {

            const items = result.filter((item) => {
                let isEvery = true;
                if (props.filter) {
                    isEvery = Object.keys(props.filter).every((filterItem: string) => {
                        return props.filter[filterItem] === item[filterItem]
                    });
                }

                return item.Status !== "D" && isEvery && item.Organisation === user.Organization.toString();
            });

            setData(items);

            setSpinTip("");
        });
    }, []);

    useEffect(() => {
        form.setFieldsValue(editItem);
    }, [editItem]);

    const onFinish = (values: Car) => {
        saveChanges(values);
    }

    const saveChanges = (changedItem: Car) => {
        let result = {} as any[];

        if (data.some((item: any) => item.Id === changedItem.Id)) {
            result = data.map(item => (item.Id === changedItem.Id ? changedItem : item));
            setData(result);
        } else {
            const carNumberPlate = changedItem.CarNumberPlate.replace(/\s/g, '').toUpperCase();
            changedItem.Id = carNumberPlate;
            changedItem.Status = "N";
            setData(state => [...state, changedItem]);
        }

        updateItemOnDb(TABLE_NAME, changedItem, changedItem.Id);
        setEditItem({} as Car);
    }

    const [spinTip, setSpinTip] = useState("Loading...");

    const headerContent = () => <>
        <Row>
            <Col span={23}>
                {/* <Text strong>{t(TABLE_NAME)}</Text> */}
            </Col>
            <Col span={1}>
                <Button shape="round" icon={<PlusOutlined />} onClick={() => { setEditItem({ Id: "-1" } as any); form.resetFields(); }} />
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D") })
        }
        return undefined;
    }

    const formContent = <>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                size={"small"}
                initialValues={{}}
                onFinish={onFinish}
            >
                <Form.Item name={"CarNumberPlate"} label={t("CarNumberPlate")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"InsuranceDate"} label={t("InsuranceDate")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"PassengerCount"} label={t("PassengerCount")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"KM"} label={t("KM")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"VehicleInspectionDate"} label={t("VehicleInspectionDate")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Status"} label={t("Status")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Id"} label={t("Id")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Col span={6}>
                            <Button htmlType="button" style={{ margin: '0 8px', minWidth: "80px" }} onClick={() => { setEditItem({} as Car) }}>
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button style={{ margin: '0 8px', minWidth: "80px" }} type="primary" htmlType="submit" name="submit_button"> {t("Save")} </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Col>
    </>;

    const tableContent =
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
                rowKey="Id"
                scroll={{ x: true }}
                title={headerContent}
                columns={columns}
                dataSource={getDataSource()}
            />

        </Col>;


    return (<Spin spinning={spinTip !== ""} tip={spinTip}>
        <Row justify="center" align="middle">
            {
                editItem.Id
                    ? formContent
                    : tableContent
            }
        </Row>
    </Spin>);

}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult };
};

export default connect(mapStateToProps, {
    handlePopup
})(FHCar);
