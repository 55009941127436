import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import React from "react";
import PassengerContext from "../../Context/Passenger";
import { getTableItemWithId } from "../../dto/ServerHelper";
import UserContext from "../../Context/User";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }: any) {

  const [user, setUser] = useContext(UserContext);

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#FF4F00");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [countOfPassengers, setCountOfPassengers] = useState(0);

  const openDrawer = () => setVisible(!visible);
  const closeDrawer = () => setVisible(false);
  const handleSidenavType = (type: any) => setSidenavType(type);
  const handleSidenavColor = (color: any) => setSidenavColor(color);
  const handleFixedNavbar = (type: any) => setFixed(type);

  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  useEffect(() => {
    getTableItemWithId("PassengerCount", user.Organization).then((result: any) => {
      setCountOfPassengers(parseInt(result.Count));
    })
  }, [])

  return (
    pathname === "sign-in" || pathname === "privacy-veli" || pathname === "privacy-surucu" || pathname === "privacy-managment" ? <></> :
      <PassengerContext.Provider value={countOfPassengers}>
        <Layout
          className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
            } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
        >
          <Drawer
            title={false}
            placement={placement === "right" ? "left" : "right"}
            closable={false}
            onClose={() => setVisible(false)}
            visible={visible}
            key={placement === "right" ? "left" : "right"}
            width={250}
            className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
              } `}
          >
            <Layout
              className={`layout-dashboard ${pathname === "rtl" ? "layout-dashboard-rtl" : ""
                }`}
            >
              <Sider
                trigger={null}
                width={250}
                theme="light"
                className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
                  }`}
                style={{ background: sidenavType }}
              >
                <Sidenav color={sidenavColor} closeDrawer={closeDrawer} />
              </Sider>
            </Layout>
          </Drawer>
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
            trigger={null}
            width={250}
            theme="light"
            className={`sider-primary ant-layout-sider-primary ${sidenavType === "#fff" ? "active-route" : ""
              }`}
            style={{ background: sidenavType }}
          >
            <Sidenav color={sidenavColor} closeDrawer={closeDrawer} />
          </Sider>
          <Layout>
            {fixed ? (
              <Affix>
                <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                  <Header
                    onPress={openDrawer}
                    name={pathname}
                    subName={pathname}
                    handleSidenavColor={handleSidenavColor}
                    handleSidenavType={handleSidenavType}
                    handleFixedNavbar={handleFixedNavbar} placement={undefined} />
                </AntHeader>
              </Affix>
            ) : (
              <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
                <Header
                  onPress={openDrawer}
                  name={pathname}
                  subName={pathname}
                  handleSidenavColor={handleSidenavColor}
                  handleSidenavType={handleSidenavType}
                  handleFixedNavbar={handleFixedNavbar} placement={undefined} />
              </AntHeader>
            )}
            <Content className="content-ant">{children}</Content>
            <Footer />
          </Layout>
        </Layout>
      </PassengerContext.Provider>
  );
}

export default Main;
