import React, { useContext, useEffect, useState } from "react";
import { FullScreen } from "ol/control";
import MapContext from "../FHMapContext";

const FullScreenControl = () => {
    const { map }: any = useContext(MapContext);
    useEffect(() => {
        if (!map) return;
        // eslint-disable-next-line prefer-const
        let fullScreenControl = new FullScreen({});
        map.controls.push(fullScreenControl);

        return () => map.controls.remove(fullScreenControl);
    }, [map]);
    return null;
};
export default FullScreenControl;