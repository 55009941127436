

import { Button, Form, Input, Popconfirm, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems, updateItemOnDb } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { handlePopup } from '../../redux/actions/actions';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Car } from '../../model/Car';

const { Text } = Typography;

interface BBSchoolProps {
    id?: any;
    onClickListItem?: any;
    filter?: any;
}

function BBSchool(props: BBSchoolProps) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [editItem, setEditItem] = useState({} as Car);

    const TABLE_NAME = "School";

    const columns = [
        {
            "title": t("Name"),
            "dataIndex": "Name",
            "key": "Name"
        },
        {
            "title": t("ContactPersonName"),
            "dataIndex": "ContactPersonName",
            "key": "ContactPersonName"
        },
        {
            "title": t("ContactPersonSurname"),
            "dataIndex": "ContactPersonSurname",
            "key": "ContactPersonSurname"
        },
        {
            "title": t("ContactNumber"),
            "dataIndex": "ContactNumber",
            "key": "ContactNumber"
        },
        {
            "title": t("Adress"),
            "dataIndex": "Adress",
            "key": "Adress"
        },
        {
            "title": t("Latitude"),
            "dataIndex": "Latitude",
            "key": "Latitude"
        },
        {
            "title": t("Longitude"),
            "dataIndex": "Longitude",
            "key": "Longitude"
        },
        {
            "title": t("Status"),
            "dataIndex": "Status",
            "key": "Status"
        },
        {
            "title": t("Id"),
            "dataIndex": "Id",
            "key": "Id"
        },
        {
            title: t('Action'),
            dataIndex: '',
            key: 'x',
            fixed: 'right',
            render: (_: any, record: any) =>
                <>
                    <Popconfirm title="Silmek istediğinizden emin misiniz?" onConfirm={() => { record.Status = "D"; saveChanges(record); }}>
                        <a>Sil</a>
                    </Popconfirm>
                    <br></br>
                    {<a onClick={() => setEditItem(record)}>Güncelle</a>}
                </>
            ,
        }
    ] as any;

    useEffect(() => {
        getTableItems(TABLE_NAME).then((result: any[]) => {

            const items = result.filter((item) => {
                let isEvery = true;
                if (props.filter) {
                    isEvery = Object.keys(props.filter).every((filterItem: string) => {
                        return props.filter[filterItem] === item[filterItem]
                    });
                }

                return item.Status !== "D" && isEvery;
            });

            setData(items);

            setSpinTip("");
        });
    }, []);

    useEffect(() => {
        form.setFieldsValue(editItem);
    }, [editItem]);

    const onFinish = (values: Car) => {
        saveChanges(values);
    }

    const saveChanges = (changedItem: Car) => {
        let result = {} as any[];

        if (data.some((item: any) => item.Id === changedItem.Id)) {
            result = data.map(item => (item.Id === changedItem.Id ? changedItem : item));
            setData(result);
        } else {
            const maxId = data.length === 0 ? 0 : Math.max( ...data.map(function (o) { return o.Id; }))
            changedItem.Id = (maxId + 1).toString();
            changedItem.Status = "N";
            setData(state => [...state, changedItem]);
        }

        updateItemOnDb(TABLE_NAME, changedItem, changedItem.Id);
        setEditItem({} as Car);
    }

    const [spinTip, setSpinTip] = useState("Loading...");

    const headerContent = () => <>
        <Row>
            <Col span={23}>
                {/* <Text strong>{t(TABLE_NAME)}</Text> */}
            </Col>
            <Col span={1}>
                <Button shape="round" icon={<PlusOutlined />} onClick={() => { setEditItem({ Id: "-1" } as any); form.resetFields(); }} />
            </Col>
        </Row>
    </>;

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D") })
        }
        return undefined;
    }

    const formContent = <>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                layout="horizontal"
                size={"small"}
                initialValues={{}}
                onFinish={onFinish}
            >
                <Form.Item name={"Name"} label={t("Name")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"ContactPersonName"} label={t("ContactPersonName")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"ContactPersonSurname"} label={t("ContactPersonSurname")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"ContactNumber"} label={t("ContactNumber")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Adress"} label={t("Adress")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Latitude"} label={t("Latitude")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Longitude"} label={t("Longitude")} rules={[{ required: true, message: 'Lütfen eksik alanları doldurunuz!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Status"} label={t("Status")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item name={"Id"} label={t("Id")} hidden={true}>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Row justify="end">
                        <Col span={6}>
                            <Button htmlType="button" style={{ margin: '0 8px', minWidth: "80px" }} onClick={() => { setEditItem({} as Car) }}>
                                {t("Cancel")}
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Button style={{ margin: '0 8px', minWidth: "80px" }} type="primary" htmlType="submit" name="submit_button"> {t("Save")} </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Col>
    </>;

    const tableContent =
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
                rowKey="Id"
                scroll={{ x: true }}
                title={headerContent}
                columns={columns}
                dataSource={getDataSource()}
            />

        </Col>;


    return (<Spin spinning={spinTip !== ""} tip={spinTip}>
        <Row justify="center" align="middle">
            {
                editItem.Id
                    ? formContent
                    : tableContent
            }
        </Row>
    </Spin>);

}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult };
};

export default connect(mapStateToProps, {
    handlePopup
})(BBSchool);
