import React, { useContext, useEffect, useState } from 'react';
import MapContext from "./../FHMapContext";
import { Draw, Modify, Select, Snap } from 'ol/interaction';
import { connect } from 'react-redux';
import { changePassengerCoordinate } from '../../../redux/actions/actions';
import { MarkerCoordinate } from '../../../model/MarkerCoordinate';
import { transform } from 'ol/proj';

function FHMapInteractions(props: any) {

    const [select, setSelect] = useState(null as any);
    const [modify, setModify] = useState(null as any);
    const { map }: any = useContext(MapContext);

    const ExampleModify = {
        init: function () {
            // eslint-disable-next-line prefer-const
            let select = new Select();
            map.addInteraction(select);

            // eslint-disable-next-line prefer-const
            let modify = new Modify({
                features: select.getFeatures(),
            });

            modify.on(['modifystart'], function (evt) {
console.log()
             });

            modify.on(['modifyend'], function (evt: any) {
                const coor = evt.features.getArray()[0].getGeometry().getFirstCoordinate();
                // eslint-disable-next-line prefer-const
                let lonlat1 = transform(coor, 'EPSG:3857', 'EPSG:4326');
                props.changePassengerCoordinate({ Id: "", Latitude: lonlat1[1], Longitude: lonlat1[0] } as MarkerCoordinate);
            });

            map.addInteraction(modify);

            setModify(modify);

            this.setEvents(select);

        },
        setEvents: function (select: any) {
            const selectedFeatures = select.getFeatures();

            select.on('change:active', function () {
                selectedFeatures.forEach(function (each: any) {
                    selectedFeatures.remove(each);
                });
            });
            setSelect(select);
        },
        setActive: function (select: any, active: boolean) {
            select.setActive(active);
            modify.setActive(active);
        },
    };

    useEffect(() => {

        if (!map) return;
        ExampleModify.init();

        const snap = new Snap({
            source: props.source,
        });
        map.addInteraction(snap);
    }, [map])

    return <></>;
}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult };
};

export default connect(mapStateToProps, {
    changePassengerCoordinate
})(FHMapInteractions);