
import { Alert, Avatar, Button, Form, Input, List, message, Popconfirm, Row, Select, Space, Table, TimePicker } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Spin } from 'antd';
import { BranchesOutlined, DeleteOutlined, DribbbleCircleFilled, EditOutlined, InfoCircleOutlined, LikeOutlined, MessageOutlined, PlusOutlined, StarOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { deleteItemOnDb, getTableItems, getTableItemWithId, updateItemOnDb } from '../dto/ServerHelper';
import { Passenger } from '../model/Passenger';
import UserContext from '../Context/User';

const { Text } = Typography;
const { Option } = Select;


function ApproveLocation(props:any) {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [spinTip, setSpinTip] = useState("Loading...");
    const [user, setUser] = useContext(UserContext);

    const TABLE_NAME = "PassengerTemporaryLocation";
    const PASSENGER_TABLE_NAME = "Passenger";

    useEffect(() => {
        getTableItems(TABLE_NAME).then((result: any) => {

            const items = Object.keys(result).map((key, index) => {
                if (result[key].Organisation === user.Organization.toString()) {
                    return result[key];
                }
            }).filter(item => item !== undefined)
            setData(items);

            setSpinTip("");
        });

    }, []);

    const approve = (changedItem: any) => {

        getTableItemWithId<Passenger>(PASSENGER_TABLE_NAME, changedItem.Id).then((passengerItem: Passenger) => {
            passengerItem.Latitude = changedItem.Latitude;
            passengerItem.Longitude = changedItem.Longitude;
            updateItemOnDb(PASSENGER_TABLE_NAME, passengerItem, changedItem.Id).then(() => {
                message.success('Onaylandı.');
                deleteItemOnDb(`/${TABLE_NAME}/${changedItem.Id}`);
                const newData = data.filter((itemData) => { return itemData.Id !== changedItem.Id });
                setData(newData);
            }).catch(() => {
                message.error("Güncellenemedi tekrar deneyiniz!")
            })

        }).catch(() => {
            message.error("Öğrenci bilgisi alınamadı tekrar deneyiniz!")
        })

    }

    const refuse = (changedItem: any) => {
        deleteItemOnDb(`/${TABLE_NAME}/${changedItem.Id}`).then(() => {
            message.success("Konum güncellemesi rededildi.");
            const newData = data.filter((itemData) => { return itemData.Id !== changedItem.Id });
            setData(newData);
        }).catch(() => {
            message.error("Güncellenemedi tekrar deneyiniz!")
        })
    }

    return (
        <>
            {
                <Spin spinning={spinTip !== ""} tip={spinTip}>
                    <Row justify="center" align="middle">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <List
                                itemLayout="horizontal"
                                size="small"
                                dataSource={data}
                                footer={<></>}
                                renderItem={item => (
                                    <List.Item
                                        key={item.ShuttleName}
                                        actions={[
                                            <Space key={"acbdk"}>
                                                <EditOutlined></EditOutlined>
                                                <a style={{ color: "black" }} onClick={() => {
                                                    approve(item)
                                                }} key="list-vertical-like-o" >{"Onayla"}</a>
                                            </Space>,
                                            <Space key={"acbf"}>
                                                <InfoCircleOutlined></InfoCircleOutlined>
                                                <a style={{ color: "black" }} onClick={() => {
                                                    refuse(item)
                                                }} key="list-vertical-message" >{"Reddet"}</a>
                                            </Space>
                                        ]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar icon={<DribbbleCircleFilled />} />}
                                            title={`${item.PassengerName} - ${item.ShuttleId}`}
                                            description={
                                                <>
                                                    <Row>
                                                        <Col>
                                                            {`${item.Address}`}
                                                        </Col>
                                                    </Row>
                                                </>
                                            }

                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Spin>
            }
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {};
};

export default connect(mapStateToProps, {
})(ApproveLocation);