
import { Button, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTableItems } from '../../dto/ServerHelper';
import { Col, Spin } from 'antd';
import { handlePopup } from '../../redux/actions/actions';
import { Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import FHDrawer from '../FHDrawer';

const { Text } = Typography;

interface FHInventoryProps {
    id?: any;
    expandedRowRender(record: any): React.ReactNode;
    handlePopup: any;
    tableName: string;
    onClickListItem?: any;
    filter?: any;
}

function FHInventory(props: FHInventoryProps) {

    const { t } = useTranslation();
    const [data, setData] = useState([] as any[]);
    const [formFields, setFormFields] = useState([] as any[]);
    const [columns, setColumns] = useState([] as any[]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [editContent, setEditContent] = useState(<></>);

    useEffect(() => {
        getTableItems(props.tableName).then((result: any[]) => {
            if (result.length > 0) {

                setFormFields(Object.keys(result[0]));
                console.log(Object.keys(result[0]));
                const columnsTemp = getColumns();

                setColumns(columnsTemp);
            }

            const items = result.filter((item) => {
                let isEvery = true;
                if (props.filter) {
                    isEvery = Object.keys(props.filter).every((filterItem: string) => {
                        return props.filter[filterItem] === item[filterItem]
                    });
                }

                return item.Status !== "D" && isEvery;
            });

            setData(items);

            setSpinTip("");
        });
    }, []);

    useEffect(() => {
        const columnsTemp = getColumns();
        console.log(columnsTemp);
        setColumns(columnsTemp);
    }, [data]);

    const getColumns = () => {
        // eslint-disable-next-line prefer-const
        let columnsTemp = formFields.map((item: any) => { return { title: t(item), dataIndex: item, key: item } });

        return columnsTemp;
    }

    const onOk = () => {
        props.handlePopup(true);
        setPopupVisible(false);
    }

    const onCancel = () => {
        setPopupVisible(false);
    }

    const [spinTip, setSpinTip] = useState("Loading...");

    const getDataSource = () => {
        if (data) {
            return data.filter((item: any) => { return (item.Status !== "D") })
        }
        return undefined;
    }

    const list = <Spin spinning={spinTip !== ""} tip={spinTip}>
        <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table
                    rowKey="Id"
                    scroll={{ x: true }}
                    columns={columns}
                    expandable={{
                        expandedRowRender: record => props.expandedRowRender(record),
                        rowExpandable: record => record.name !== 'Not Expandable',
                    }}
                    dataSource={getDataSource()}
                />

                <FHDrawer
                    content={editContent}
                    visible={popupVisible}
                    onClose={onCancel}
                    title={"İçerik Düzenleme"}
                    bodyStyle={{ paddingBottom: 80 }}
                    width={720}
                    extra={
                        <Space>
                            <Button onClick={onCancel}>Vazgeç</Button>
                            <Button onClick={onOk} type="primary">
                                Kaydet
                            </Button>
                        </Space>
                    }
                ></FHDrawer>
            </Col>
        </Row>
    </Spin>;

    return list;

}

const mapStateToProps = (state: any) => {
    const popupResult = state.popupResult;
    return { popupResult };
};

export default connect(mapStateToProps, {
    handlePopup
})(FHInventory);