
import { Alert, Button, DatePicker, DatePickerProps, message } from 'antd'
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getTableItems, getTableItemsWithPath, insertItemOnDb, updateItemOnDb } from '../../dto/ServerHelper';
import { Shuttle } from '../../model/Shuttle';
import { ShuttleRoute } from '../../model/ShuttleRoute';
import * as turf from '@turf/turf';

const RouteDetermination = (props: any) => {

    const [shuttlRouteByDate, setShuttlRouteByDate] = useState({} as any);

    const save = () => {
        if (shuttlRouteByDate && !isEmpty(shuttlRouteByDate)) {
            updateItemOnDb("ShuttleBaseTrackRoute", shuttlRouteByDate, props.shuttle.Id).then(() => {
                message.success("Kaydedildi.");
            })
        }
    }

    const showAllShuttlesPolygon = () => {
        let temp = {
            "type": "FeatureCollection",
            "features": [

            ]
        };

        getTableItems(`Shuttle`).then((shuttle: any) => {

            shuttle.forEach((element: Shuttle) => {
                let shuttleCoors: number[][] = [];
                if (element.Organisation === "2" && element.Direction === "Gidiş") {

                    getTableItemsWithPath(`/ShuttleBaseTrackRoute/${element.Id}`).then((response: any) => {

                        if (response) {

                            const keyOfObj = Object.keys(response);

                            keyOfObj.forEach(element => {
                                let shuttleRoute = response[element] as ShuttleRoute;
                                shuttleCoors.push([shuttleRoute.Longitude, shuttleRoute.Latitude] as never);
                            });

                            var tempFeature = {
                                "type": "Feature",
                                "properties": {
                                    "name": `${element.CarNumberPlate}`
                                },
                                "geometry": {
                                    "type": "MultiPolygon",
                                    "coordinates": []
                                }
                            }

                            tempFeature.geometry.coordinates.push([shuttleCoors] as never);

                            temp.features.push(tempFeature as never);
                        }

                    });
                }


            });

        });

        setTimeout(() => {

            props.setAllShuttlesPolygonFeature(temp)
        }, 10000);


    }

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (!date) {
            return;
        }

        const currentDate = date.format('YYYYMMDD')
        props.setShuttlRouteDate(currentDate)

        getTableItemsWithPath(`/ShuttleRoute/${props.shuttle.Id}/${currentDate}`).then((response: any) => {

            if (!response) {
                props.setShuttlRouteByDate([]);
                return;
            }


            // eslint-disable-next-line prefer-const
            let keyOfObj = Object.keys(response);
            const result = [] as number[][];
            keyOfObj.forEach(element => {
                const shuttleRoute = response[element] as ShuttleRoute;

                const coordinates = [
                    shuttleRoute.Longitude,
                    shuttleRoute.Latitude
                ];
                result.push(coordinates)


            })
            props.setShuttlRouteByDate(result);
            setShuttlRouteByDate(response);
        })
    };

    return (
        <>
            {props.isCalculateRoute && <DatePicker onChange={onChange} />}
            {props.isCalculateRoute && <Button size="small" ghost onClick={() => { save() }}>Rota Kaydet</Button>}
            <Button size="small" ghost onClick={() => { showAllShuttlesPolygon() }}>Bütün Servisleri Göster</Button>
        </>
    )
}

const mapStateToProps = (state: any) => {
    const user = state.user;
    const school = state.school;
    return { user, school };
};

export default connect(mapStateToProps)(RouteDetermination);
